import * as _ from "lodash";

import { DataDescriptorEntity, ChartSeriesType, Enterprise as App } from "@ctra/api";
import { i18nTranslate, Enterprise } from "@ctra/i18n";
import { KPIThresholdDownV2, KPIThresholdUpV2 } from "@ctra/components";
import { memoize } from "@ctra/utils";

import { OnboardingInsightValidation, VulcanV2InsightBody } from "@insights";

import { GenericInsightBase } from "./Base";

interface VulcanV2InsightMetadata {
  stage: number;
  variantId: DataDescriptorEntity["id"];
  variantName: DataDescriptorEntity["dataProperties"]["typeName"];
  subjectType: ChartSeriesType; // or ChartFilterType
  subject: string;
  changeRel: number;
  modelVersion: string;
  lengthDays: number;
}

const {
  kpi,
  insights: {
    entity: { titleParam }
  }
} = Enterprise;

/**
 * Automated KPI insight
 */
class VulcanV2Insight extends GenericInsightBase {
  @memoize
  getMetadata(): VulcanV2InsightMetadata {
    return super.getMetadata() as VulcanV2InsightMetadata;
  }

  getDescription() {
    return "";
  }

  /**
   * Get parameters for translating the title and the description
   */
  @memoize
  getTranslationParams() {
    const { variantName: typeName, subjectType, subject } = this.getMetadata();

    let groupName = subject;

    switch (subjectType) {
      case "farm": {
        groupName = _.defaultTo(App.entities.getFarm(App.store.getState(), { farmID: +subject })?.name, "");
        break;
      }
      case "dimGroup": {
        groupName = _.defaultTo(App.entities.getDIMGroup(App.store.getState(), { id: subject }), {
          name: ""
        }).name;
        break;
      }
      case "penSubType": {
        groupName = i18nTranslate(titleParam(subjectType, subject));
        break;
      }
    }

    const kpiName = i18nTranslate(kpi.displayName(typeName), {
      variant: "short",
      case: null,
      makeDefaultValue: true
    });

    return {
      kpi: kpiName,
      groupName
    };
  }

  /**
   * Replace the icons based on the metadata and add its own validation
   */
  @memoize
  getComponents(): ReturnType<typeof GenericInsightBase.prototype.getComponents> {
    const { changeRel } = this.getMetadata();

    return {
      Body: VulcanV2InsightBody,
      Icon: changeRel > 0 ? KPIThresholdUpV2 : KPIThresholdDownV2,
      ListIcon: changeRel > 0 ? KPIThresholdUpV2 : KPIThresholdDownV2,
      Validation: OnboardingInsightValidation
    };
  }
}

export { VulcanV2Insight };
