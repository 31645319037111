import { AsyncType, createAsyncTypes, createCRUDAsyncTypes } from "@ctra/utils";

const ns = "app/farm-summary";

/**
 * CRUD farm summary
 */
const [
  /**
   * @category Type
   */
  CREATE_FARM_SUMMARY,
  /**
   * @category Type
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _FETCH_FARM_SUMMARY,
  /**
   * @category Type
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _UPDATE_FARM_SUMMARY,
  /**
   * @category Type
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _DELETE_FARM_SUMMARY
]: Array<AsyncType> = createCRUDAsyncTypes(ns, "FARM_SUMMARY", { primary: "id" });

/**
 * Fetch subscription
 * @type {AsyncType}
 */
const FETCH_SUBSCRIPTION_LIST: AsyncType = createAsyncTypes(ns, "FETCH_SUBSCRIPTION_LIST");

/**
 * Update subscription
 * @type {AsyncType}
 */
const UPDATE_SUBSCRIPTION: AsyncType = createAsyncTypes(ns, "UPDATE_SUBSCRIPTION");

export default {
  CREATE_FARM_SUMMARY,
  FETCH_SUBSCRIPTION_LIST,
  UPDATE_SUBSCRIPTION
};
