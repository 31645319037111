import { ComponentProps, FC, useMemo, useState } from "react";
import * as _ from "lodash";
import { useRouteMatch } from "react-router-dom";

import {
  Timeline,
  Typography,
  ImpactTracking,
  Button,
  AntDesignSelect as Select,
  Row,
  Col,
  PlusCircleOutlined,
  FilterOutlined,
  Skeleton,
  LoadingOutlined
} from "@ctra/components";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { useGoogleAnalytics } from "@ctra/analytics";
import { EventEntity, GroupedEventList, EventOrigin } from "@ctra/api";

import { InsightTypeContext } from "@insights";
import { useFarm } from "@farms";
import { EventContext, GAActions, useCreateEventDialog, useEventList } from "@events";

import { FarmTimelineItem } from "../FarmTimelineItem";
import styles from "./FarmTimeline.module.less";

const { WidgetWrapper } = ImpactTracking;
const { Paragraph } = Typography;

type FarmTimelineProps = ComponentProps<typeof WidgetWrapper>;

/**
 * Farm timeline component
 * @param {React.PropsWithChildren<WidgetWrapperProps & {children?: React.ReactNode | undefined}>} props
 * @return {JSX.Element}
 * @constructor
 */
export const FarmTimeline: FC<FarmTimelineProps> = (props) => {
  const { trackEvent } = useGoogleAnalytics();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>();
  const { farm } = useFarm();

  const {
    api: { open }
  } = useCreateEventDialog();

  const {
    events,
    meta: { isLoading: isEventListLoading, hash }
  } = useEventList<GroupedEventList>();

  const {
    params: { id: highlightedEventID }
  } = useRouteMatch<{ id: EventEntity["id"] }>();

  const {
    timeline: { filter: filterCopy }
  } = Content;

  /**
   * Filter the event list based on the selected filter
   * @type {Dictionary<any>}
   */
  const eventList = useMemo(() => {
    return _.omitBy(
      _.mapValues(events, (group) =>
        _.filter(group, (event) => {
          switch (filter) {
            case EventOrigin.automated:
              return !event.context.userID;
            case EventOrigin.manual:
              return !!event.context.userID;
            default:
              return !filter || event.subCategory === filter;
          }
        })
      ),
      _.isEmpty
    );
  }, [filter, events]);

  return (
    <InsightTypeContext.Provider>
      <InsightTypeContext.Consumer>
        {({ meta: { isLoading: isInsightTypeListLoading } }) => {
          /**
           * Initial fetching should display a spinner and an empty list
           */
          const isFetching = isInsightTypeListLoading || (_.isEmpty(events) && isEventListLoading);

          /**
           * Background updating should just display a spinner in the title
           */
          const isUpdating = !_.isEmpty(events) && isEventListLoading;

          /**
           * Event subcategories are used as filter values
           * @type {string[]}
           */
          const filterValues: string[] = _.uniq(_.map(_.flatten(_.values(events)), "subCategory"));

          /**
           * Event sources are used as filter values too
           * @type {string[]}
           */
          const eventOrigins: string[] = [EventOrigin.automated, EventOrigin.manual];

          return (
            <WidgetWrapper
              scrollable
              className={styles.TimelineWrapper}
              label={
                <Row justify="space-between" align="middle" wrap={false} gutter={16}>
                  <Col flex={1}>
                    <Select
                      className={styles.ReduceTextSize}
                      allowClear
                      style={{ width: "100%" }}
                      suffixIcon={<FilterOutlined />}
                      placeholder={t<string>(filterCopy.placeholder)}
                      value={filter}
                      onChange={(value) => setFilter(value)}
                    >
                      <Select.OptGroup label={t<string>(filterCopy.group.origin)} key="origin">
                        {_.map(eventOrigins, (value) => (
                          <Select.Option className={styles.ReduceTextSize} value={value} key={value}>
                            {t<string>(filterCopy.origin(value))}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label={t<string>(filterCopy.group.type)} key="category">
                        {_.map(filterValues, (value) => (
                          <Select.Option className={styles.ReduceTextSize} value={value} key={value}>
                            {t<string>(filterCopy.type(value))}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Col>
                  <Col>
                    <Button
                      className={styles.ReduceTextSize}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        trackEvent(GAActions.openAddEvent, {
                          label: _.defaultTo(farm?.name, void 0)
                        });

                        open(hash, farm?.id);
                      }}
                    >
                      {t(Content.events.list.headers.addEvent)}
                    </Button>
                  </Col>
                </Row>
              }
              {...props}
            >
              <Skeleton active loading={isFetching}>
                {isUpdating && (
                  <section className={styles.Loading}>
                    <LoadingOutlined />
                  </section>
                )}
                <Timeline>
                  {_.map(eventList, (group, label) => (
                    <div key={label}>
                      <Paragraph className={styles.GroupLabel}>{_.capitalize(label)}</Paragraph>
                      <div>
                        {_.map(group, (event) => (
                          <EventContext.Provider eventID={event.id} key={event.id}>
                            <FarmTimelineItem highlighted={event.id === highlightedEventID} />
                          </EventContext.Provider>
                        ))}
                      </div>
                    </div>
                  ))}
                </Timeline>
              </Skeleton>
            </WidgetWrapper>
          );
        }}
      </InsightTypeContext.Consumer>
    </InsightTypeContext.Provider>
  );
};
