import * as AsyncAPI from "./async";
import * as AuthAPI from "./auth";
import * as FarmsAPI from "./farms";
import * as BreadcrumbsAPI from "./breadcrumbs";
import * as UserAPI from "./user";
import * as SessionAPI from "./session";
import * as DataDictionaryAPI from "./data-dictionary";
import * as ChartAPI from "./chart";
import * as HerdGroupsAPI from "./herd-groups";
import * as PensAPI from "./pens";
import * as ScorecardAPI from "./scorecard";
import * as DIMGroupsAPI from "./dim-groups";
import * as InsightsAPI from "./insights";
import * as CowsAPI from "./cows";
import * as NotificationsAPI from "./notifications";
import * as PreferencesAPI from "./preferences";
import * as EventsAPI from "./events";
import * as LabelsAPI from "./labels";
import * as SavedCardsAPI from "./saved-cards";
import * as ImpactTrackingAPI from "./impact-tracking";
import * as InvitationAPI from "./invitation";
import * as SignupAPI from "./signup";
import * as FarmSummariesAPI from "./farm-summaries";
import * as IofcAPI from "./iofc";
import * as AccountAPI from "./account";
import * as GenericAPI from "./generic";

export * from "./async/typings";
export * from "./auth/typings";
export * from "./farms/typings";
export * from "./breadcrumbs/typings";
export * from "./user/typings";
export * from "./data-dictionary/typings";
export * from "./chart/typings";
export * from "./herd-groups/typings";
export * from "./scorecard/typings";
export * from "./dim-groups/typings";
export * from "./insights/typings";
export * from "./cows/typings";
export * from "./preferences/typings";
export * from "./events/typings";
export * from "./labels/typings";
export * from "./saved-cards/typings";
export * from "./impact-tracking/typings";
export * from "./invitation/typings";
export * from "./pens/typings";
export * from "./signup/typings";
export * from "./session/typings";
export * from "./iofc/typings";
export * from "./account/typings";
export * from "./generic/typings";
export * from "./farm-summaries/typings";

export const Async = AsyncAPI;
export const Auth = AuthAPI;
export const Farms = FarmsAPI;
export const Breadcrumbs = BreadcrumbsAPI;
export const User = UserAPI;
export const Session = SessionAPI;
export const DataDictionary = DataDictionaryAPI;
export const Charts = ChartAPI;
export const HerdGroups = HerdGroupsAPI;
export const Pens = PensAPI;
export const Scorecard = ScorecardAPI;
export const DIMGroups = DIMGroupsAPI;
export const Insights = InsightsAPI;
export const Cows = CowsAPI;
export const Notifications = NotificationsAPI;
export const Preferences = PreferencesAPI;
export const Events = EventsAPI;
export const Labels = LabelsAPI;
export const SavedCards = SavedCardsAPI;
export const ImpactTracking = ImpactTrackingAPI;
export const Invitation = InvitationAPI;
export const Signup = SignupAPI;
export const FarmSummaries = FarmSummariesAPI;
export const IOFC = IofcAPI;
export const Account = AccountAPI;
export const Generic = GenericAPI;
