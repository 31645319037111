import { useContext, createContext, useState, FC } from "react";
import { TFunction, TOptions, default as i18n, i18n as i18nFunction } from "i18next";
import { useTranslation as originalUseTranslation } from "react-i18next";
import { useDeepCompareEffect } from "use-deep-compare";
import * as _ from "lodash";
import * as Sentry from "@sentry/react";

import { getStorageKey } from "../utils/versioning";
import { AssetError, assetSeparator } from "../utils";

interface ContextType {
  translationContext: Record<string, unknown>;
  setTranslationContext: (translationContext: Record<string, unknown>) => void;
  t: CustomTFunction;
}

interface TranslationProviderProps {
  /**
   * Translation context value that comes from outside e.g { unitSystem: "metric "}
   */
  value?: Record<string, unknown>;
}

interface CustomTOptions extends TOptions {
  makeDefaultValue?: boolean;
  onAssetError?: string | ((error: AssetError) => string);
}

type CustomTFunction = TFunction extends <T extends string | void>(...args: infer U) => infer R
  ? <T extends string>(...args: [asset: string | AssetError, options?: CustomTOptions]) => string
  : string;

/**
 * Return type for our modified useTranslation hook
 */
interface UseTranslationReturnType {
  t: CustomTFunction;
  i18n: i18nFunction;
  setTranslationContext: (translationContext: Record<string, unknown>) => void;
}

/**
 * Default translation context
 */
export const DefaultContext = createContext<ContextType>({
  translationContext: {},
  setTranslationContext: _.noop,
  t: _.noop as CustomTFunction
});

const isAssetError = (asset: string | AssetError): asset is AssetError => {
  return (asset as AssetError).error !== undefined;
};

/**
 * Translation unit provider
 * @param children
 * @param translationContext
 * @private
 */
const _TranslationProvider: FC<TranslationProviderProps> = ({ value, children }) => {
  const sentryExceptionStorageKey = `${getStorageKey({ persist: false })}.localization.sentryException`;
  const storage = JSON.parse(_.defaultTo(localStorage.getItem(sentryExceptionStorageKey), "[]"));

  const [currentContext, setCurrentContext] = useState<Record<string, unknown>>(value || {});
  const [sentryExceptions, setSentryExceptions] = useState<string[]>(storage);

  const previousContext = useContext(DefaultContext);
  const { t } = originalUseTranslation();

  const setContextValue = (newContext: Record<string, unknown>): void => {
    setCurrentContext(newContext);
  };

  const translationContext = { ...previousContext.translationContext, ...currentContext };

  /**
   * Save the sentry exceptions to the local storage
   */
  useDeepCompareEffect(() => {
    localStorage.setItem(sentryExceptionStorageKey, JSON.stringify(sentryExceptions));
  }, [sentryExceptions]);

  /**
   * Inject the context in t
   * @param {string} asset
   * @param {CustomTOptions} params
   * @return {ReturnType<TFunction>}
   */
  const modifiedT: CustomTFunction = (asset: string | AssetError, params = {}): string => {
    let defaultGeneratedValue;

    if (isAssetError(asset)) {
      console.error(asset.error, _.omit(asset, "error"));

      return _.isFunction(params.onAssetError)
        ? params.onAssetError(asset)
        : _.isString(params.onAssetError)
        ? params.onAssetError
        : asset.result;
    }

    const { makeDefaultValue, defaultValue: defaultInputValue, ...restParams } = params;

    if (makeDefaultValue && !defaultInputValue) {
      const [, key] = asset.split(".");
      const path = key.split(assetSeparator);

      defaultGeneratedValue = _.upperFirst(_.lowerCase(_.last(path)));
    }

    const { res, usedKey } = t(asset, {
      ...translationContext,
      ...restParams,
      defaultValue: defaultInputValue || defaultGeneratedValue,
      returnDetails: true
    });

    if (makeDefaultValue && defaultGeneratedValue && res === usedKey) {
      if (!_.includes(sentryExceptions, usedKey)) {
        /**
         * Capture a Sentry exception to see what happens
         */
        Sentry.captureException(new Error(`${usedKey} is not yet translated.`), {
          tags: {
            diagnostics: "i18nError"
          }
        });

        setSentryExceptions([...sentryExceptions, usedKey]);
      }

      return defaultGeneratedValue;
    }

    return res;
  };

  return (
    <DefaultContext.Provider
      value={{
        t: modifiedT,
        translationContext,
        setTranslationContext: setContextValue
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const TranslationContext = {
  Provider: _TranslationProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * The modified useTranslation hook that also has a translation context within "t"
 */
export const useTranslation = (): UseTranslationReturnType => {
  const { setTranslationContext, t } = useContext(DefaultContext);
  const { t: originalT, ...rest } = originalUseTranslation();

  return { t, setTranslationContext, ...rest };
};

/**
 * The modified translation function in i18n to be used by pure functions
 * also has localization context
 */
export const i18nTranslate = (asset: string | AssetError, params?: Record<string, unknown>): string => {
  const localStorageKey = `${getStorageKey({ persist: true })}.localization`;
  const defaultUnitSystem = "si";

  const unitSystem = localStorage.getItem(localStorageKey) || defaultUnitSystem;

  const translationContext = {
    unitSystem
  };

  return isAssetError(asset) ? asset.result : i18n.t(asset, { ...translationContext, ...params });
};
