import { ComponentProps, FC, ReactNode, useEffect, useState } from "react";
import { Layout, Grid } from "antd";
import * as _ from "lodash";

import { classname } from "@ctra/utils";

import { CtraLogo } from "../../../elements";
import styles from "./ApplicationWrapper.module.less";

const { Content, Sider, Header, Footer } = Layout;

export interface ApplicationWrapperProps extends ComponentProps<typeof Layout> {
  /**
   * sandbox mode
   */
  sandboxMode?: boolean;
  /**
   * dialog mode
   */
  dialogMode?: boolean;
  /**
   * components which will be rendered on top of the application (dialogs, modals, non-visuals, etc.)
   */
  floaters?: ReactNode;
  /**
   * components which will be rendered inside the sider
   */
  sider?:
    | ReactNode
    | (({
        isCollapsed,
        toggle
      }: {
        isCollapsed: boolean;
        toggle: (collapsed: boolean) => void;
      }) => ReactNode);
  /**
   * handler for sider collapse event
   * @param {boolean} collapsed
   */
  onSiderCollapse?: (collapsedBefore: boolean, collapsedAfter: boolean) => void;
  /**
   * components which will be rendered inside the header
   */
  header?: ReactNode;
  /**
   * components which will be rendered inside the footer
   */
  footer?: ReactNode;
}

/**
 * Application wrapper component with sidebar, header and footer
 * @param {string | undefined} className
 * @param {boolean | undefined} sandboxMode
 * @param {boolean | undefined} dialogMode
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} floaters
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | (({isCollapsed, toggle}: {isCollapsed: boolean, toggle: (collapsed: boolean) => void}) => React.ReactNode)} sider
 * @param {((collapsedBefore: boolean, collapsedAfter: boolean) => void) | undefined} onSiderCollapse
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} header
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} footer
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @param {Omit<React.PropsWithChildren<ApplicationWrapperProps>, "sandboxMode" | "dialogMode" | "floaters" | "footer" | "children" | "onSiderCollapse" | "header" | "className" | "sider">} rest
 * @returns {JSX.Element}
 * @constructor
 */
const ApplicationWrapper: FC<ApplicationWrapperProps> = ({
  className,
  sandboxMode,
  dialogMode,
  floaters,
  sider,
  onSiderCollapse,
  header,
  footer,
  children,
  ...rest
}) => {
  const { md, lg } = Grid.useBreakpoint();
  const [collapsedSider, setCollapsedSider] = useState<boolean>(!_.isUndefined(lg) && !lg);

  /**
   * auto collapse sidebar on mobile
   */
  useEffect(() => {
    setCollapsedSider(!lg);
  }, [lg]);

  return (
    <Layout
      className={classname("ctra-generic-applicationWrapper", styles.ApplicationWrapper, className)}
      {...rest}
    >
      {/* floater components */}
      {_.defaultTo(floaters, null)}

      {/* sidebar */}
      {sider && md ? (
        <Sider
          width={220}
          collapsedWidth={64}
          collapsible
          collapsed={_.defaultTo(collapsedSider, void 0)}
          className={styles.SiderWrapper}
          onCollapse={(collapsed, type) => {
            if (type === "clickTrigger") {
              if (_.isFunction(onSiderCollapse)) {
                onSiderCollapse(collapsedSider, collapsed); // trackEvent(collapsedSider ? GAActions.collapseSidebar : GAActions.expandSidebar);
              }

              setCollapsedSider(collapsed);
            }
          }}
        >
          <Layout className={styles.SiderContent}>
            <Header className={styles.Logo}>
              <CtraLogo
                variant={collapsedSider ? "noText" : "withText"}
                className={collapsedSider ? "no-text" : "with-text"}
              />
            </Header>
            <Content>
              {_.isFunction(sider)
                ? sider({ isCollapsed: collapsedSider, toggle: (collapsed) => setCollapsedSider(collapsed) })
                : sider}
            </Content>
          </Layout>
        </Sider>
      ) : null}

      {/* main content */}
      <Content>
        <Layout style={{ minHeight: "100vh" }}>
          {header ? (
            <Header className={classname(styles.HeaderWrapper, sandboxMode ? styles.Sandbox : null)}>
              {header}
            </Header>
          ) : null}
          <Content className={classname(styles.PageContent)}>
            <Layout>
              <Content>{children}</Content>
            </Layout>
          </Content>
          {!md && footer ? <Footer className={styles.FooterWrapper}>{footer}</Footer> : null}
        </Layout>
      </Content>
    </Layout>
  );
};

export default ApplicationWrapper;
