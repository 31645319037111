export const base = {
  _root: "/app"
};

export const pbl = {
  _root: "/public",
  chart: {
    _root: "/chart/:shortID"
  }
};
