import { createModule, from } from "../../utils";

export const farmSummaries = createModule("farmSummaries", {
  page: {
    ...from("title", "description"),
    alerts: {
      ...from("error", "success"),
      selectFarm: from("title", "description")
    },
    create: {
      ...from("title", "description", "loading"),
      form: {
        labels: from("timePeriod", "submit")
      }
    }
  },
  settings: {
    ...from("title", "description"),
    toggle: from("label", "description"),
    alerts: {
      selectFarm: from("title", "description")
    }
  }
});
