import { useDrag, useDrop } from "react-dnd";
import { ComponentProps, FC, useRef, Key, MouseEventHandler } from "react";
import Markdown from "react-markdown";
import { Col, Row, Space, Tooltip, Typography } from "antd";
import * as _ from "lodash";

import { classname } from "@ctra/utils";

import {
  ActionKey,
  BrowserOutlined,
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  ShareAltOutlined
} from "../../../..";

import { ContentWrapper } from "../../../layout";

import styles from "./ChartCard.module.less";

const { Text } = Typography;

const type = "CHART_CARD";

export interface ChartCardProps extends ComponentProps<typeof ContentWrapper> {
  /**
   * index
   */
  index?: Key;
  /**
   * Metric title
   */
  metric: string;
  /**
   * Variant title
   */
  variant: string;
  /**
   * Source title
   */
  source?: string;
  /**
   * labels for the tooltips
   */
  labels?: { remove?: string; edit?: string; report?: string; share?: string };
  /**
   * delete handler
   */
  handleDelete?: MouseEventHandler<HTMLSpanElement>;
  /**
   * configuration handler
   */
  handleConfigure?: MouseEventHandler<HTMLSpanElement>;
  /**
   * report handler
   */
  handleReport?: MouseEventHandler<HTMLSpanElement>;
  /**
   * share handler
   */
  handleShare?: MouseEventHandler<HTMLSpanElement>;
  /**
   * delete handler
   */
  handleDrag?: (dragIndex: Key, hoverIndex: Key) => void;
}

/**
 * Chart card that wraps up the chart
 * @param {string | number} index
 * @param {string} metric
 * @param {string} variant
 * @param {string | undefined} source
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | (React.ReactElement<any, string | React.JSXElementConstructor<any>> & undefined) | (Iterable<React.ReactNode> & undefined) | (React.ReactPortal & undefined) | (Iterable<ReactI18NextChild> & React.ReactElement<any, string | React.JSXElementConstructor<any>>) | (Iterable<ReactI18NextChild> & string) | (Iterable<ReactI18NextChild> & number) | (Iterable<ReactI18NextChild> & {}) | (Iterable<ReactI18NextChild> & Iterable<React.ReactNode>) | (Iterable<ReactI18NextChild> & React.ReactPortal) | (Iterable<ReactI18NextChild> & boolean) | (Iterable<ReactI18NextChild> & null) | (Iterable<ReactI18NextChild> & undefined) | (Iterable<ReactI18NextChild> & React.ReactElement<any, string | React.JSXElementConstructor<any>> & undefined) | (Iterable<ReactI18NextChild> & Iterable<React.ReactNode> & undefined) | (Iterable<ReactI18NextChild> & React.ReactPortal & undefined) | (Iterable<ReactI18NextChild> & undefined & React.ReactElement<any, string | React.JSXElementConstructor<any>>) | (Iterable<ReactI18NextChild> & undefined & Iterable<React.ReactNode>) | (Iterable<ReactI18NextChild> & undefined & React.ReactPortal)} children
 * @param {string | undefined} className
 * @param {{remove: string, edit: string, report: string}} labels
 * @param {(event: React.MouseEvent<HTMLSpanElement>) => void} handleDelete
 * @param {(event: React.MouseEvent<HTMLSpanElement>) => void} handleConfigure
 * @param {((event: React.MouseEvent<HTMLSpanElement>) => void) | undefined} handleReport
 * @param {(dragIndex: React.Key, hoverIndex: React.Key) => void} handleDrag
 * @param {((event: React.MouseEvent<HTMLSpanElement>) => void) | undefined} handleShare
 * @param {Omit<React.PropsWithChildren<ChartCardProps>, "handleShare" | "index" | "className" | "source" | "handleConfigure" | "labels" | "handleDelete" | "handleDrag" | "metric" | "children" | "variant" | "handleReport">} rest
 * @returns {JSX.Element}
 * @constructor
 */
const ChartCard: FC<ChartCardProps> = ({
  index,
  metric,
  variant,
  source,
  children,
  className,
  labels = {},
  handleDelete,
  handleConfigure,
  handleReport,
  handleDrag,
  handleShare,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: styles.Dropping,
        className: styles.Div
      };
    },
    drop: (item: { index: Key }) => {
      if (!!index && _.isFunction(handleDrag)) {
        handleDrag(item.index, index);
      }
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(drag(ref));

  return (
    <div
      style={{
        opacity: isDragging ? 0.5 : 1
      }}
      ref={ref}
      className={isOver ? styles.Dropping : styles.Div}
    >
      <ContentWrapper className={classname(className, styles.ChartCard)} {...rest}>
        <Row justify="space-between">
          <Text className={styles.Title}>{metric}</Text>
          <Space size="middle" className={styles.Icons}>
            {_.isFunction(handleReport) && (
              <Tooltip title={<Markdown>{_.get(labels, ["report"], "report")}</Markdown>}>
                <Text type="secondary">
                  <WarningOutlined onClick={handleReport} />
                </Text>
              </Tooltip>
            )}
            {_.isFunction(handleDelete) && (
              <Tooltip title={<Markdown>{_.get(labels, ["remove"], "remove")}</Markdown>}>
                <Text type="secondary">
                  <DeleteOutlined onClick={handleDelete} />
                </Text>
              </Tooltip>
            )}
            {_.isFunction(handleConfigure) && (
              <Tooltip title={<Markdown>{_.get(labels, ["edit"], "edit")}</Markdown>}>
                <Text type="secondary">
                  <EditOutlined onClick={handleConfigure} />
                </Text>
              </Tooltip>
            )}
            {_.isFunction(handleShare) && (
              <Tooltip title={<Markdown>{_.get(labels, ["share"], "share")}</Markdown>}>
                <Text type="secondary">
                  <ShareAltOutlined onClick={handleShare} />
                </Text>
              </Tooltip>
            )}
          </Space>
        </Row>
        <Row gutter={[24, 0]} className={styles.Metric}>
          <Col>
            <Space align="center">
              <ActionKey />
              <Text type="secondary">{variant}</Text>
            </Space>
          </Col>
          <Col>
            {source && (
              <Space align="center">
                <BrowserOutlined />
                <Text type="secondary">{source}</Text>
              </Space>
            )}
          </Col>
        </Row>
        <Row>{children}</Row>
      </ContentWrapper>
    </div>
  );
};

export default ChartCard;
