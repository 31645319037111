import { FC } from "react";
import * as _ from "lodash";
import Markdown from "react-markdown";

import {
  Charts,
  Analytics,
  DndProvider,
  HTML5Backend,
  CtraLayout,
  Typography,
  Alert,
  Grid,
  Skeleton,
  Button
} from "@ctra/components";

import { ShareChartResponse, ShareChartResponseAttachment } from "@ctra/api";
import { classname, Optional } from "@ctra/utils";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";

import { useCurrentUser } from "@auth";

import { usePublicChart } from "../../providers";
import styles from "./PublicChart.module.less";

const { Chart: ChartUI } = Charts;

const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { Paragraph } = Typography;

const {
  V3: { ChartCard }
} = Analytics;

const {
  analytics: {
    share: { publicPage }
  }
} = Content;

export const PublicChart: FC = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const { md, lg } = Grid.useBreakpoint();

  const {
    meta: { isFetching },
    api: { isError },
    chart,
    attachment
  } = usePublicChart();

  const attachments: ShareChartResponseAttachment[] = _.get(chart, "attachments", []);
  const image: Optional<ShareChartResponseAttachment> = _.find(attachments, { contentType: "image/jpeg" });

  const metadata = _.merge(
    {
      locale: "en-US"
    },
    _.isError(chart) ? {} : _.get(chart, ["context", "metadata"])
  );

  const metric = metadata.metric ? t<string>(metadata.metric, { locale: metadata.locale }) : "";
  const variant = metadata.variant ? t<string>(metadata.variant, { locale: metadata.locale }) : "";
  const source = metadata.source ? t<string>(metadata.source, { locale: metadata.locale }) : "";
  const translationParams = { ...metadata, metric, variant, source };

  return (
    <section style={user.id ? void 0 : { width: lg ? "50%" : "100%", margin: "0 auto" }}>
      <Skeleton loading={isFetching}>
        <WidgetWrapper title={t<string>(publicPage.title, translationParams)}>
          {!isError(chart) && _.get(chart, ["text"]) && (
            <ContentWrapper isLoading={isFetching} className={classname(styles.Widget)}>
              <Markdown>
                {t<string>(publicPage.description, {
                  ...translationParams,
                  message: chart!.text
                })}
              </Markdown>
            </ContentWrapper>
          )}
          {!user.id && (
            <ContentWrapper
              accent="teal"
              className={classname(styles.Widget)}
              title={t<string>(publicPage.cta.title, translationParams)}
            >
              <Markdown>{t<string>(publicPage.cta.text, translationParams)}</Markdown>
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  debugger;
                }}
              >
                {t<string>(publicPage.cta.button)}
              </Button>
            </ContentWrapper>
          )}
          <DndProvider backend={HTML5Backend}>
            <ChartCard
              style={user.id ? { width: "50%" } : void 0}
              isLoading={isFetching}
              index={0}
              metric={metric}
              variant={variant}
              source={source}
            >
              <ChartUI>
                <img style={{ width: "100%" }} src={image?.url} alt="" />
              </ChartUI>
            </ChartCard>
          </DndProvider>
        </WidgetWrapper>
      </Skeleton>
    </section>
  );
};
