import { FC, useRef } from "react";
import * as _ from "lodash";
import * as Sentry from "@sentry/react";

import { Col, Row, Skeleton } from "@ctra/components";
import { classname } from "@ctra/utils";

import { Chart, ChartFilterContext, ChartAPIContext } from "@chart";
import { useInsight, InsightValidation, VulcanV2Insight } from "@insights";

import baseStyles from "../InsightBody/InsightBody.module.less";
import styles from "./VulcanV2InsightBody.module.less";

/**
 * Insight body for KPI insights
 * @constructor
 */
export const VulcanV2InsightBody: FC = () => {
  const {
    getMetadata,
    insight: { farm },
    meta: { isLoading }
  } = useInsight<VulcanV2Insight>();

  const { variantId: id, subject } = getMetadata();
  const ref = useRef<any>();

  return (
    <Skeleton active loading={isLoading}>
      <Row className={classname(baseStyles.Row, baseStyles.Padded)}>
        <Col flex={1}>
          <InsightValidation />
        </Col>
      </Row>
      <Sentry.ErrorBoundary fallback={<>{null}</>}>
        <Row className={classname(styles.ChartContainer, baseStyles.Row, baseStyles.PaddedChart)}>
          <Col flex={1}>
            <ChartFilterContext.Provider series={[_.trim(subject)]}>
              <ChartAPIContext.Provider dataDescriptorID={id} farmID={farm}>
                <Chart
                  ref={ref}
                  viewOptions={{ showToolbar: true, zoomEnabled: false }}
                  config={{
                    onReady: (plot) => {
                      ref.current = plot;
                    }
                  }}
                />
              </ChartAPIContext.Provider>
            </ChartFilterContext.Provider>
          </Col>
        </Row>
      </Sentry.ErrorBoundary>
    </Skeleton>
  );
};
