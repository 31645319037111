import { combineReducers } from "redux";
import * as _ from "lodash";

import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import { types as dataDictionaryTypes } from "../data-dictionary";
import { NormalizedMetricCollection } from "../data-dictionary/schemas";
import { ChartList, ChartState, ChartDataState, CorrelationList } from "./typings";
import { FetchChartDataFulfilledPayload, FetchChartDataRejectedPayload } from "./actions";
import types from "./types";

export const initialState: ChartState = {
  config: {},
  data: {},
  correlations: {}
};

/**
 * Chart config reducer
 * @param state
 * @param action
 * @category Reducer
 */
export const chartConfigReducer: Reducer<ChartList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case dataDictionaryTypes.FETCH_METRICS.fulfilled: {
      const {
        entities: { chart }
      } = payload as NormalizedMetricCollection;

      return {
        ...state,
        ...chart
      };
    }
  }

  return state;
};

/**
 * Chart data reducer matching cache keys with iframe urls
 * @param state
 * @param action
 * @category Reducer
 */
export const chartDataReducer: Reducer<ChartDataState> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_CHART_DATA.pending: {
      const { hash } = payload as FetchChartDataFulfilledPayload;

      return state[hash]
        ? state
        : ({
            ...state,
            [hash]: {
              data: {
                data: [],
                meta: {}
              }
            }
          } as ChartDataState);
    }
    case types.FETCH_CHART_DATA.fulfilled: {
      const { hash, data, requestURL } = payload as FetchChartDataFulfilledPayload;

      return {
        ...state,
        [hash]: { data, requestURL }
      } as ChartDataState;
    }

    case types.FETCH_CHART_DATA.rejected: {
      const { hash, error } = payload as FetchChartDataRejectedPayload;

      return {
        ...state,
        [hash]: { error }
      } as ChartDataState;
    }
  }

  return state;
};

/**
 * Correlations reducer
 * @param state
 * @param action
 * @category Reducer
 */
export const correlationsReducer: Reducer<CorrelationList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_FARM_CORRELATIONS.fulfilled: {
      const { intervals, farmID } = payload;

      return {
        ...state,
        [farmID]: {
          ...state[farmID],
          index: intervals
        }
      } as CorrelationList;
    }
    case types.FETCH_CHART_CORRELATIONS.fulfilled: {
      const { intervals, farmID } = payload;

      return {
        ...state,
        [farmID]: {
          ...state[farmID],
          intervals: {
            ..._.get(state, [farmID, "intervals"], {}),
            ...intervals
          }
        }
      };
    }
  }

  return state;
};

/**
 * @category Reducer
 */
export const reducer = combineReducers({
  config: cleanup<ChartList>({})(chartConfigReducer),
  data: cleanup<ChartDataState>({})(chartDataReducer),
  correlations: cleanup<CorrelationList>({})(correlationsReducer)
});

export default cleanup<ChartState>(initialState)(reducer);
