import { createContext, FC, useContext } from "react";

interface ContextType {
  a?: string;
}

const DefaultContext = createContext<ContextType>({});

const _Provider: FC = ({ children }) => {
  return <DefaultContext.Provider value={{}}>{children}</DefaultContext.Provider>;
};

export const ScreenshotContext = {
  Provider: _Provider,
  Consumer: DefaultContext.Consumer
};

export const useChartScreenshot = () => useContext<ContextType>(DefaultContext);
