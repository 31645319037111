import { FC } from "react";
import * as _ from "lodash";

import { CtraLayout, Typography, Switch, Row, Col, Alert, LoadingOutlined, Space } from "@ctra/components";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { FarmSummarySubscription } from "@ctra/api";
import { Optional } from "@ctra/utils";

import { useFarmSummarySubscriptions } from "@farm-summaries";
import { useFarm } from "@farms";

const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { Text } = Typography;

const {
  farmSummaries: {
    settings: { title, toggle, alerts }
  }
} = Content;

/**
 * Farm briefing settings
 * @returns {JSX.Element}
 * @constructor
 */
export const FarmBriefingSettings: FC<{ placement: "main" | "settings" }> = ({ placement }) => {
  const { t } = useTranslation();

  const {
    meta: { isLoading, isUpdating },
    subscriptions,
    api: { update }
  } = useFarmSummarySubscriptions();

  const { farm } = useFarm();

  /**
   * Find the subscription for the given farm.
   * @type {Optional<FarmSummarySubscription>}
   */
  const subscription: Optional<FarmSummarySubscription> = _.find(
    subscriptions,
    ({ farmID }) => farmID === farm?.id
  );

  return (
    <WidgetWrapper title={t<string>(title, { placement })}>
      {farm?.id ? (
        <ContentWrapper isLoading={isLoading}>
          <Row>
            <Col flex={1}>
              <Space>
                <Text>
                  {t<string>(toggle.description, {
                    farmName: farm.name
                  })}
                </Text>
                {isUpdating && <LoadingOutlined />}
              </Space>
            </Col>
            <Col>
              <Switch
                checked={subscription && subscription.isEnabled}
                onChange={(on) => update(farm.id, on)}
                disabled={isUpdating}
              />
            </Col>
          </Row>
        </ContentWrapper>
      ) : (
        <Alert
          type="info"
          showIcon
          message={t<string>(alerts.selectFarm.title)}
          description={t<string>(alerts.selectFarm.description)}
        />
      )}
    </WidgetWrapper>
  );
};
