import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import { NormalizedInsightList } from "../insights/schemas";
import { types as insightTypes } from "../insights";
import { DairyCowList } from "./typings";

export const initialState: DairyCowList = {};

/**
 * Herd group reducer
 * @param state
 * @param action
 */
const reducer: Reducer<DairyCowList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case insightTypes.FETCH_INSIGHTS.fulfilled: {
      const {
        entities: { dairyCow }
      } = payload as NormalizedInsightList;

      return {
        ...state,
        ...dairyCow
      };
    }
  }

  return state;
};

export default cleanup<DairyCowList>(initialState)(reducer);
