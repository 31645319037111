import * as _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";

import { DataDescriptorEntity } from "@ctra/api";
import { AntDesignSelect as Select, Col, Row, Button } from "@ctra/components";
import { defaultLocale, Enterprise as Content, useTranslation } from "@ctra/i18n";
import { useGoogleAnalytics } from "@ctra/analytics";

import { useEvent } from "@events";
import { useDataDictionary } from "@base";

import { GAActions } from "../../../analytics";

const { Option, OptGroup } = Select;

const {
  kpi: { category, displayName },
  analytics: {
    correlations: { add, headers },
    compare: {
      dropdowns: { placeholder, notFound }
    }
  }
} = Content;

/**
 * Chart picker component
 * @param {(value: DataDescriptorEntity["id"]) => void} onChange
 * @param {((variant: DataDescriptorEntity) => boolean) | undefined} filterFn
 * @param {string} placement
 * @param {string | undefined} defaultValue
 * @param {boolean} showLabel
 * @param {boolean} showButton
 * @param {Array<DataDescriptorEntity["id"]> | undefined} filterValues
 * @param {Array<string> | undefined} metricType
 * @returns {JSX.Element}
 * @constructor
 */
export const ChartPicker: FC<{
  placement?: "impactTracking" | "analytics";
  onChange: (value: DataDescriptorEntity["id"]) => void;
  defaultValue?: DataDescriptorEntity["id"];
  showLabel?: boolean;
  showButton?: boolean;
  filterValues?: Array<DataDescriptorEntity["id"]>;
  metricType?: Array<string>;
  filter?: (variant: DataDescriptorEntity) => boolean;
}> = ({
  onChange,
  filter: filterFn,
  placement = "analytics",
  defaultValue,
  showLabel = true,
  showButton = true,
  filterValues,
  metricType
}) => {
  const [filter, setFilter] = useState<string>("");
  const [value, setValue] = useState<DataDescriptorEntity["id"]>();
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const { search } = useLocation();
  const { event } = useEvent();

  const {
    dataDescriptors,
    subsets: { farm: farmDataDescriptorIDList, eventScoped: eventScopedDataDescriptorList }
  } = useDataDictionary();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const dataDescriptorID = searchParams.get("dataDescriptorID");

    if (dataDescriptorID && dataDescriptorID !== defaultValue) {
      onChange(dataDescriptorID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  /**
   * Get the data dictionary for the given farm id / event scope
   * @type {Pick<DataDescriptorList, keyof DataDescriptorList>}
   */
  const dataDictionary = _.pickBy(dataDescriptors, (variant) => {
    const {
      id,
      dataProperties: { type }
    } = variant;
    const idMatches = (event ? eventScopedDataDescriptorList : farmDataDescriptorIDList).includes(id);
    const typeMatches = !metricType || metricType.includes(type);
    const filterMatches = !_.isFunction(filterFn) || filterFn(variant);

    return filterMatches && idMatches && typeMatches;
  });

  /**
   * Get the dropdown data
   * @type {unknown[]}
   */
  const items = _.map(
    _.groupBy(dataDictionary, ({ dataProperties: { category } }) => category),
    (list, groupName) => ({
      key: groupName,
      label: t<string>(category(groupName)),
      options: _.map(list, ({ id, dataProperties: { typeName } }) => ({
        key: id,
        value: id,
        label: t<string>(displayName(typeName), {
          case: null,
          variant: placement === "impactTracking" ? void 0 : "short",
          makeDefaultValue: true
        })
      }))
    })
  );

  /**
   * Filter the options based on search query
   */
  const searchFiltered = items.reduce((acc: typeof items, data) => {
    const filteredGroupOptions = data.options.filter((option) =>
      option.label.toLowerCase().includes(filter.toLowerCase())
    );

    if (filteredGroupOptions.length > 0) {
      acc.push({
        ...data,
        options: filteredGroupOptions
      });
    }

    return acc;
  }, []);

  return (
    <Row className="ant-form-item-row" gutter={16}>
      {showLabel && (
        <Col span={24} className="ant-form-item-label">
          {t<string>(headers.chartSelect)}
        </Col>
      )}
      <Col className="ant-form-item-control" flex={1}>
        <Select
          style={{ width: "100%" }}
          placeholder={t<string>(placeholder.chart)}
          onSearch={setFilter}
          onChange={event ? onChange : setValue}
          value={value}
          filterOption={false}
          showSearch
          notFoundContent={t(notFound)}
        >
          {searchFiltered.map(({ label, options }) => {
            /**
             * filter the values if props specify any filter values
             */
            const filtered = _.filter(options, ({ value }) => !_.includes(filterValues, value));

            return (
              !_.isEmpty(filtered) && (
                <OptGroup key={label} label={label}>
                  {filtered.map(({ label, value }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </OptGroup>
              )
            );
          })}
        </Select>
      </Col>
      {showButton && (
        <Col>
          <Button
            disabled={!value}
            type="primary"
            onClick={() => {
              if (value) {
                debugger;
                // TODO test if `value` is a data descriptior id

                trackEvent(GAActions.selectChart, {
                  label: t<string>(displayName(dataDictionary[value].dataProperties.typeName), {
                    lng: defaultLocale,
                    case: null,
                    variant: null
                  })
                });

                onChange(value);
                setValue(void 0);
                setFilter("");
              }
            }}
          >
            {t<string>(add)}
          </Button>
        </Col>
      )}
    </Row>
  );
};
