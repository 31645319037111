import { FC, createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";

import { EnterpriseAppState, ShareChartResponse, Charts, ShareChartPayload } from "@ctra/api";
import { message } from "@ctra/components";
import { Nullable, isPending, isFulfilled, Optional, readableHash } from "@ctra/utils";
import { URIState } from "@routes";
import { useDataDictionary, useLocalization } from "@base";
import { Enterprise as Content } from "@ctra/i18n";
import { useCurrentUser } from "@auth";

type Error = ReturnType<typeof Charts.actions.fetchSharedChart.reject>["payload"];

interface ContextType {
  chart: Nullable<ShareChartResponse | Error>;
  attachment: Nullable<Record<string, unknown> | Error>;
  api: {
    isError: (value: unknown) => value is Error;
    publish: () => void;
    update: (id: ShareChartResponse["id"], email: string, message: Optional<string>) => void;
  };
  meta: {
    isUpdating: boolean;
    isPublishing: boolean;
    isFetching: boolean;
    isUpdated: boolean;
  };
}

const {
  analytics: {
    config: {
      metric: { displayName },
      variant: variantCopy,
      source: sourceCopy
    }
  }
} = Content;

const DefaultContext = createContext<ContextType>({
  chart: null,
  attachment: null,
  api: {
    // @ts-expect-error - this is a default value
    isError: () => false,
    publish: _.noop,
    update: _.noop
  },
  meta: {
    isUpdating: false,
    isPublishing: false,
    isFetching: true,
    isUpdated: false
  }
});

/**
 * Check if the value is an error
 * @param value
 * @returns {value is Error}
 */
const isError = (value: unknown): value is Error => {
  return _.has(value, "error");
};

/**
 * Context provider
 * @param {string} shortID
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @returns {JSX.Element}
 */
const _Provider: FC<{ shortID?: ShareChartResponse["shortId"] }> = ({ shortID, children }) => {
  const dispatch = useDispatch();
  const { state } = useLocation<URIState>();
  const { locale } = useLocalization();

  const {
    api: { getFullname }
  } = useCurrentUser();

  const {
    api: { extractSource, extractChartInfo }
  } = useDataDictionary();

  const {
    dataDescriptorID,
    chart: chartEntity,
    farmID,
    filterContext,
    requestURL,
    base64Content
  } = _.defaultTo(state?.shareChart, {} as NonNullable<URIState["shareChart"]>);

  /**
   * Hash for the chart (if there is any)
   * @type {string}
   */
  const hash = readableHash({ dataDescriptorID, ...filterContext });

  /**
   * Pick up the chart which is recently loaded
   */
  const [, chart] = useSelector<EnterpriseAppState, [boolean, ShareChartResponse | Error]>((state) =>
    isFulfilled(state, Charts.types.FETCH_SHARED_CHART, { withPayload: true })
  );

  /**
   * Pick up the attachment which is recently loaded
   */
  const [, attachment] = useSelector<EnterpriseAppState, [boolean, Record<string, unknown> | Error]>(
    (state) => isFulfilled(state, Charts.types.FETCH_SHARED_ATTACHMENT, { withPayload: true })
  );

  /**
   * Tells if the chart or the attachment is loading
   * @type {boolean}
   */
  const isFetching = useSelector<EnterpriseAppState, boolean>(
    (state) =>
      isPending(state, Charts.types.FETCH_SHARED_CHART) ||
      isPending(state, Charts.types.FETCH_SHARED_ATTACHMENT)
  );

  /**
   * Tells if the chart is being published
   */
  const [isPublishing, publishResponse] = useSelector<
    EnterpriseAppState,
    [boolean, ShareChartResponse | Error]
  >((state) =>
    isPending(state, Charts.types.SHARE_CHART, {
      withPayload: true,
      primaryValue: hash
    })
  );

  /**
   * Tells if the chart is being updated
   */
  const [isUpdating, updateResponse] = useSelector<EnterpriseAppState, [boolean, ShareChartResponse | Error]>(
    (state) =>
      isPending(state, Charts.types.UPDATE_SHARED_CHART, {
        withPayload: true,
        primaryValue: hash
      })
  );

  /**
   * Tell if the update is successful
   * @type {boolean}
   */
  const isUpdated = useSelector<EnterpriseAppState, boolean>((state) =>
    isFulfilled(state, Charts.types.UPDATE_SHARED_CHART, {
      primaryValue: hash
    })
  );

  /**
   * Publish the chart to get a shareable URL
   */
  const publish = () => {
    /**
     * Metric/source/variant info
     * @type {Optional<ChartSource> | null}
     */
    const source = dataDescriptorID ? extractSource(dataDescriptorID) : null;

    /**
     * Chart info
     * @type {ChartInfo | null}
     */
    const info = source ? extractChartInfo(source) : null;

    if (info) {
      const payload: ShareChartPayload = {
        context: {
          sourceId: dataDescriptorID,
          sourceType: "Chart",
          farmId: farmID,
          startAt: filterContext.timePeriod.startDate,
          endAt: filterContext.timePeriod.endDate,
          metadata: {
            locale,
            sharedBy: getFullname(),
            metric: info.nameToDisplay ? displayName(info.nameToDisplay) : void 0,
            variant: info.variantType ? variantCopy(info.variantType) : void 0,
            source: info.sourceName ? sourceCopy(info.sourceName) : void 0
          }
        },
        accessLevel: "PublicRead",
        type: "Snapshot",
        name: info.nameToDisplay,
        text: "",
        attachments: _.compact([
          base64Content
            ? {
                name: info.nameToDisplay,
                base64Content
              }
            : null,
          {
            name: info.nameToDisplay,
            contentUrl: requestURL
          }
        ])
      };

      dispatch(Charts.actions.shareChart.start({ hash, ...payload }));
    } else {
      throw new Error("Chart cannot be published, `source` or `info` seems to be missing.");
    }
  };

  /**
   * Update the shared chart
   * @param {ShareChartResponse["id"]} id
   * @param {string} email
   * @param {Nullable<string>} message
   * @param {string} onSuccess
   */
  const update = (
    id: ShareChartResponse["id"],
    email: string,
    message: Nullable<string>,
    onSuccess?: string
  ) => {
    dispatch(
      Charts.actions.updateSharedChart.start({
        accessLevel: "PublicRead",
        hash,
        id: id,
        userTags: [
          {
            email
          }
        ],
        text: _.defaultTo(message, void 0)
      })
    );
  };

  useEffect(() => {
    if (shortID) {
      dispatch(Charts.actions.fetchSharedChart.start(shortID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortID]);

  // useEffect(() => {
  //   if (isUpdated) {
  //     message.success("The email has been successfully sent to the recipient.");
  //   }
  // }, [isUpdated]);

  /**
   * Share response from either the publish or update response
   * @type {ShareChartResponse | null}
   */
  const shareResponse = updateResponse || publishResponse;

  /**
   * Chart data from either the short id fetch response or the publish/update response
   * @type {null | ShareChartResponse | Error}
   */
  const chartData = _.defaultTo(chart, isPublishing ? null : shareResponse);

  return (
    <DefaultContext.Provider
      value={{
        chart: chartData,
        attachment,
        api: { isError, publish, update },
        meta: { isFetching, isPublishing, isUpdating, isUpdated }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const PublicChartContext = {
  Provider: _Provider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook for context
 * @returns {ContextType}
 */
export const usePublicChart = (): ContextType => useContext(DefaultContext);
