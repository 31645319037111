export enum SectionVariant {
  overview = "overview",
  farm = "farm"
}

/**
 * Get time of the day from the given moment instance
 * @todo maybe find a util for this (though quite coupled with this component)
 * @param dateTime
 */
export const getTimeOfDay = (dateTime: moment.Moment): string => {
  let timeOfDay: string;

  const afternoonSplit = 12;
  const eveningSplit = 17;
  const currentHour = parseFloat(dateTime.format("HH"));

  if (currentHour >= afternoonSplit && currentHour <= eveningSplit) {
    timeOfDay = "afternoon";
  } else if (currentHour >= eveningSplit) {
    timeOfDay = "evening";
  } else {
    timeOfDay = "morning";
  }

  return timeOfDay;
};

export { default } from "./chart-layout/v3/AnalyticsPage";
export * from "./compare-charts/ChartPicker";
export * from "./compare-charts/FarmDropdown";
export * from "./chart-layout/v3/ChartSelector";
export * from "./sharing";
