import { FC, useEffect, useState } from "react";
import * as _ from "lodash";

import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { Typography, Button, Space, Grid } from "@ctra/components";
import { CookieKeys, Cookies, Nullable } from "@ctra/utils";

import styles from "./CookieConsent.module.less";

const { Title, Paragraph, Link } = Typography;

/**
 * Get cookie consent
 * @returns {string}
 */
const getConsent = (): Nullable<string> => {
  const inCookie = Cookies.get(CookieKeys.cookieConsent);
  const inLocalStorage = localStorage.getItem(CookieKeys.cookieConsent);

  return _.defaultTo(inCookie, inLocalStorage);
};

/**
 * Set cookie consent
 * @param {string} consent
 */
const setConsent = (consent: string) => {
  localStorage.setItem(CookieKeys.cookieConsent, consent);
  Cookies.set(CookieKeys.cookieConsent, consent);
};

/**
 * Cookie consent component
 * @return {null | JSX.Element}
 * @constructor
 */
export const CookieConsent: FC = () => {
  const { t } = useTranslation();
  const [hasConsent, setHasConsent] = useState<Nullable<string>>(getConsent());
  const { md } = Grid.useBreakpoint();

  const {
    ui: { cookies }
  } = Content;

  /**
   * Set cookie consent state
   */
  useEffect(() => {
    const consent = getConsent();

    setHasConsent(consent);
  }, []);

  return _.includes(["mandatory", "optional"], hasConsent) ? null : (
    <div className={styles.Wrapper}>
      <Title level={5}>{t<string>(cookies.title)}</Title>
      <Paragraph>
        {t<string>(cookies.text)}{" "}
        <Link href="https://connecterra.ai/privacy-policy/" target="_blank">
          {t<string>(cookies.link)}
        </Link>
      </Paragraph>
      <Space direction={md ? "horizontal" : "vertical"}>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            setConsent("optional");
            setHasConsent("optional");
            window.location.reload();
          }}
        >
          {t<string>(cookies.accept, { variant: "optional" })}
        </Button>
        <Button
          size="large"
          type="secondary"
          onClick={() => {
            setConsent("mandatory");
            setHasConsent("mandatory");
          }}
        >
          {t<string>(cookies.accept, { variant: "mandatory" })}
        </Button>
      </Space>
    </div>
  );
};
