import { AjaxError } from "rxjs/ajax";

import { Nullable, UnitSystem } from "@ctra/utils";

import { ISODuration } from "./utils";
import { HerdGroupEntity } from "../herd-groups";
import { DIMGroupEntity } from "../dim-groups";
import { EventEntity, EventSourceType } from "../events";
import { DataDescriptorEntity } from "../data-dictionary";
import { FarmEntity } from "../farms";
import { PenEntity } from "../pens";
import { UserEntity } from "../user";

/**
 * Chart time interval definition
 */
export type ChartTimePeriod =
  | {
      startDate: string;
      endDate: string;
    }
  | Record<string, never>;

/**
 * Chart filter definition
 */
export interface ChartFilterDefinition {
  name: ChartFilterType;
  isMultiSelectable: boolean;
  isBackendAggregated?: boolean;
}

/**
 * Different types of chart data
 */
export enum ChartSeriesType {
  behavior = "behavior",
  component = "component",
  dimGroup = "dimGroup",
  estrusDimGroup = "estrusDimGroup",
  farm = "farm",
  fertilityMetric = "fertilityMetric",
  groupName = "groupName", // todo: seems to not exist anymore, remove this
  healthIssueCategory = "healthIssueCategory",
  healthIssueResolution = "healthIssueResolution",
  herdGroup = "herdGroup",
  ingredient = "ingredient",
  insightTypeCategory = "insightTypeCategory",
  kpis = "kpis", // todo: seems to not exist anymore, remove this
  lactation = "lactation",
  lactationGroup = "lactationGroup",
  matingSeason = "matingSeason",
  metrics = "metrics",
  penCustomType = "penCustomType",
  penName = "penName",
  penSubType = "penSubType",
  penType = "penType",
  productionStage = "productionStage",
  reproState = "reproState",
  series = "series"
}

/**
 * All the chart filter types which are supported by the BE.
 * Some of them match the series types, some of them are not.
 */
export enum ChartFilterType {
  herdGroup = "herdGroupID",
  dimGroup = "dimGroupKey",
  penName = "penName",
  penCustomType = "penCustomType",
  penId = "penIdKey"
}

/**
 * Series to filter map
 * @type {{"[ChartSeriesType.penName]": ChartFilterType, "[ChartSeriesType.dimGroup]": ChartFilterType, "[ChartSeriesType.herdGroup]": ChartFilterType, "[ChartSeriesType.penCustomType]": ChartFilterType}}
 */
export const seriesToFilterMap = {
  [ChartSeriesType.dimGroup]: ChartFilterType.dimGroup,
  [ChartSeriesType.herdGroup]: ChartFilterType.herdGroup,
  [ChartSeriesType.penName]: ChartFilterType.penName,
  [ChartSeriesType.penCustomType]: ChartFilterType.penCustomType
};

/**
 * Filter to series map. It comes handy when we filter the chart
 * and a setting a filter of a type needs to be translated to series for the renderer.
 * @type {{"[ChartFilterType.pen]": ChartSeriesType, "[ChartFilterType.dimGroup]": ChartSeriesType, "[ChartFilterType.herdGroup]": ChartSeriesType}}
 */
// @ts-ignore
export const filterToSeriesMap: Record<ChartFilterType, ChartSeriesType> = {
  [ChartFilterType.penName]: ChartSeriesType.penName,
  [ChartFilterType.dimGroup]: ChartSeriesType.dimGroup,
  [ChartFilterType.herdGroup]: ChartSeriesType.herdGroup
};

/**
 * Structure in which it is fed into the API
 */
export enum UnitSystemParam {
  imperial = "Imperial",
  metric = "SI"
}

type UnitSystemConversion = {
  [index in UnitSystem]: UnitSystemParam;
};

export const UnitSystemConversionMap: UnitSystemConversion = {
  [UnitSystem.imperial]: UnitSystemParam.imperial,
  [UnitSystem.metric]: UnitSystemParam.metric
};

export enum ChartUnits {
  h = "hours",
  kg = "kilograms",
  lb = "pounds"
}

export interface ChartFilters {
  [ChartFilterType.herdGroup]?: Array<HerdGroupEntity["id"]>;
  [ChartFilterType.dimGroup]?: Array<DIMGroupEntity["id"]>;
  [ChartFilterType.penName]?: Array<PenEntity["name"]>;
  [ChartFilterType.penCustomType]?: Array<PenEntity["customType"]>;
  [ChartFilterType.penId]?: Array<PenEntity["id"]>;
}

/**
 * Type of charts
 */
export enum ChartType {
  basicLine = "basicLine",
  basicBar = "basicBar",
  basicColumn = "basicColumn",
  table = "table",
  heatmap = "heatmap",
  stackedColumn = "stackedColumn",
  stackedPercentColumn = "stackedPercentColumn",
  stackedArea = "stackedArea",
  /**
   * @deprecated Remove once all descriptors are re-configured on the BE
   */
  dataTable = "dataTable",
  line = "line",
  lineContinuous = "lineContinuous",
  stackedBar = "stackedBar"
}

/**
 * Chart data response from server
 */
export enum ChartDataSourceType {
  dataPoints = "dataPoints"
}

/**
 * @todo Rename this to xAxis type
 */
export enum ChartDataPointXAxisType {
  date = "date",
  month = "month",
  weekDay = "weekDay",
  group = "group",
  series = "series"
}

export interface ChartViewOptions {
  showEvents?: Record<EventSourceType, boolean>;
  showAnnotations?: boolean;
  showAnomalies?: boolean;
  showSupportedViews?: boolean;
  showEventToggle?: boolean;
  showToolbar?: boolean;
  zoomed?: boolean;
  zoomEnabled?: boolean;
}

export interface ChartDataOptions {
  timePeriod?: ChartTimePeriod;
}

/**
 * Chart Entity
 */
export interface ChartEntity {
  id: string;
  title: string;
  description?: string;
  view: string;
  flags: {
    isSeriesTranslatable?: boolean;
    isTimeAdjustable?: boolean;
    isBetaChart: boolean;
    isTimeZoneSpecific?: boolean; // legacy TODO kill
    isMultiFarmFilterSupported: boolean;
    isRelativeTimeSupported?: boolean; // legacy TODO kill
    isMultiIntervalSupported?: boolean; // legacy TODO kill
  };
  dataProperties: {
    maxProjectionInterval: Nullable<ISODuration>;
    minimumDisplayInterval: ISODuration;
    defaultDisplayInterval: ISODuration;
    offset: number;
    seriesType?: ChartSeriesType;
  };
  previewImageUri?: string; // legacy TODO kill
  filters: Array<ChartFilterDefinition>;
}

export type ChartList = Record<ChartEntity["id"], ChartEntity>;

export type ChartEventsList = Array<EventEntity["id"]>;

/**
 * Metadata for data points response from server
 */
export type DataPointsMetaSource = {
  title?: string;
  seriesType?: ChartSeriesType;
  seriesField?: string;
  type?: ChartType;
  xType?: ChartDataPointXAxisType;
  yAxis?: {
    unit: Nullable<ChartUnits>;
  };
  isBackendAggregated?: boolean;
  hasAnomaly?: boolean;
  hasMissingData?: boolean;
  filters?: { options: Record<string, unknown> };
};

type TranslatedMeta = {
  axis?: {
    x: {
      title?: string;
      labels?: {
        short: Record<string, string>;
        long: Record<string, string>;
      };
    };
    y: {
      title?: string;
    };
  };
  series?: {
    title: string;
    keys: Record<string, string>;
  };
};

export type DataPointsMeta = DataPointsMetaSource & TranslatedMeta;

export interface TableMeta extends DataPointsMetaSource {
  columns: Array<Record<string, unknown>>;
  keyRows: Record<string, string>;
}

export interface HeatmapMeta extends DataPointsMeta {
  seriesField?: string;
}

export type LineSource = {
  data: {
    [seriesKey: string]: {
      points: Array<{
        x: string;
        y: Nullable<number>;
        missing?: boolean;
        context?: {
          projection?: number;
        };
        anomaly?: {
          prediction?: number;
          type?: string;
        };
      }>;
    };
  };
  meta: DataPointsMetaSource;
};

export type BarSource = {
  data: { [seriesKey: string]: { points: { x: number; y: number } } };
  meta: DataPointsMetaSource;
};

export type HeatmapSource = {
  data: { [seriesKey: string]: { points: Array<{ x: string | number; y: string; z: number }> } };
  meta: DataPointsMetaSource;
};

/**
 * Union of all the data points source types
 */
export type DataPointsSource = BarSource | LineSource | HeatmapSource;

/**
 * Union of all the different chart data source type
 */
export type ChartDataSource = DataPointsSource;

export type ChartItemData<R = unknown> = Array<R>;

export type Line = ChartItemData<{
  x: string;
  y: Nullable<number>;
  seriesField: string;
  context?: {
    projection?: number;
  };
  missing?: boolean;
  anomaly?: {
    prediction?: number;
    type?: string;
  };
}>;

export type Bar = ChartItemData<{ x: number; y: string; seriesField: string }>;

export type Column = ChartItemData<{ x: string; y: number; seriesField: string }>;

export type Heatmap = ChartItemData<{ x: string | number; y: string; z: number }>;

export type Table = ChartItemData<Record<string, Nullable<string | number | string[]>>>;

/**
 * Union of all the formatted data types
 */
export type DataType = Bar | Column | Line | Heatmap | Table;

export type MetaType = DataPointsMeta | TableMeta;

export type ChartData<D extends DataType = DataType, M extends MetaType = MetaType> = {
  data: D;
  meta: M;
};

export type LineData = ChartData<Line, DataPointsMeta>;

export type BarData = ChartData<Bar, DataPointsMeta>;

export type ColumnData = ChartData<Column, DataPointsMeta>;

export type TableData = ChartData<Table, TableMeta>;

export type HeatmapData = ChartData<Heatmap, HeatmapMeta>;

export type DataPointsData = ChartData<Bar | Column | Line | Heatmap | Table, DataPointsMeta>;

export type ChartAnnotationEntity = {
  id: string;
  position: Array<number | string>;
};

export type ChartAnnotationList = Array<ChartAnnotationEntity>;

export type ChartDataState = {
  [hash: string]: {
    data: ChartData;
    requestURL: string;
    error: AjaxError;
  };
};

/**
 * Correlation typings
 */

export type Correlation = {
  dataDescriptorID: DataDescriptorEntity["id"];
  descriptorTitle: DataDescriptorEntity["dataProperties"]["typeName"];
  correlationValue: number;
  pctChange: number;
  avgValue: number;
  maxValue: number;
  minValue: number;
};

export type Correlations = Record<DataDescriptorEntity["id"], Correlation>;

export type CorrelationList = Record<
  FarmEntity["id"],
  {
    index: CorrelationsIndexResponse["intervalDays"];
    intervals: CorrelationsResponse["intervalDays"];
  }
>;

export interface CorrelationsIndexResponse {
  farmId: number;
  intervalDays: {
    [key: string]: DataDescriptorEntity["id"][];
  };
}

export interface CorrelationsResponse {
  farmId: number;
  intervalDays: {
    [key: string]: IntervalDay;
  };
}

export interface IntervalDay {
  startTs: Date;
  endTs: Date;
  descriptors: Record<DataDescriptorEntity["id"], { statistics: Statistics; correlations: Correlations }>;
}

export interface Statistics {
  avgValue: number;
  maxValue: number;
  minValue: number;
  pctChange: number;
}

export type ChartState = {
  config: ChartList;
  data: ChartDataState;
  correlations: CorrelationList;
};

export type { ISODuration };

export type ShareChartRequestAttachment =
  | {
      name: string;
      base64Content: string;
    }
  | {
      name: string;
      contentUrl: string;
    };

interface ShareChartUserTag {
  tag?: string;
  id?: UserEntity["id"];
  email: string;
}

export interface ShareChartPayload {
  context: {
    sourceId: DataDescriptorEntity["id"];
    sourceType: "Chart";
    farmId?: FarmEntity["id"];
    startAt: string;
    endAt: string;
    metadata: Record<string, unknown>;
  };
  userTags?: Array<ShareChartUserTag>;
  accessLevel: "PublicRead";
  type: "Snapshot";
  name: string;
  text: string;
  attachments: ShareChartRequestAttachment[];
}

export interface ShareChartResponseAttachment {
  description: string;
  name: string;
  contentType: string;
  size: number;
  url: string;
  createdAt: string;
  expiresAt: string;
}

export interface ShareChartResponse {
  id: string;
  shortId: string;
  type: "Snapshot";
  accessLevel: "PublicRead";
  context: {
    sourceId: DataDescriptorEntity["id"];
    sourceType: "Chart";
    farmId: FarmEntity["id"];
    startAt: string;
    endAt: string;
    metadata: Record<string, unknown>;
  };
  name: string;
  text: string;
  attachments: ShareChartResponseAttachment[];
  userTags: unknown[];
  seenBy: Record<string, unknown>;
  createdBy: UserEntity["id"];
  createdAt: string;
  updatedAt: string;
}
