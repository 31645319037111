import { createContext, FC, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import {
  DataDescriptorList,
  EnterpriseAppState,
  Enterprise,
  DataDictionary,
  DataDescriptorEntity,
  MetricCollection,
  MetricEntity,
  ChartList,
  EnumList
} from "@ctra/api";

import { isDispatched, Optional } from "@ctra/utils";
import { useFarm, useFarmList } from "@farms";
import * as Sentry from "@sentry/react";
import { useEvent } from "@events";
import { useDeepCompareMemo } from "use-deep-compare";

export interface ChartInfo {
  familyName: Optional<string>;
  metricName: string;
  variantType: string;
  sourceName: Optional<string>;
  nameToDisplay: string;
}

type ChartSource = {
  metric: string;
  source: string | undefined;
  variant: string;
};

interface ContextType {
  /**
   * all the data descriptors
   */
  dataDescriptors: DataDescriptorList;
  /**
   * all the metrics
   */
  metrics: MetricCollection;
  /**
   * subsets of data descriptors which are frequently used
   */
  subsets: {
    /**
     * data descriptors supported by the current farm
     */
    farm: Array<DataDescriptorEntity["id"]>;
    /**
     * data descriptors which support projections
     */
    projectionsEnabled: Array<DataDescriptorEntity["id"]>;
    /**
     * data descriptors supported by the current event scope
     */
    eventScoped: Array<DataDescriptorEntity["id"]>;
    /**
     * data descriptors which may render a chart for the current scope
     */
    chartScoped: Array<DataDescriptorEntity["id"]>;
  };
  groups: {
    /**
     * metrics grouped by category
     */
    byCategory: _.Dictionary<[MetricEntity, ...MetricEntity[]]>;
    /**
     * metrics grouped by family
     */
    byFamilyCategory: {
      [x: string]: _.Dictionary<[MetricEntity, ...MetricEntity[]]>;
    };
    /**
     * all the metric families
     */
    families: Pick<_.Dictionary<[MetricEntity, ...MetricEntity[]]>, string | number>;
    /**
     * metrics which support projections
     */
    projectionMetrics: Array<MetricEntity["id"]>;
  };
  api: {
    /**
     * Extract the combination of metric/source/variant from a metric ID
     * @param {MetricEntity["id"]} variantID
     * @returns {Optional<ChartSource>}
     */
    extractSource: (variantID: MetricEntity["id"]) => Optional<ChartSource>;
    /**
     * Extract all the information needed to display a chart
     * @param {ChartSource} chart
     * @returns {{familyName: Optional<string>, metricName: string, variantType: string, sourceName: Optional<string>, nameToDisplay: string}}
     */
    extractChartInfo: (chart: ChartSource) => ChartInfo;
    /**
     * Handle a metric value change
     * @param {MetricEntity["id"]} metric
     * @param {string} source
     * @param {string} variant
     * @returns {ChartSource}
     */
    handleMetricChanges: (metric: MetricEntity["id"], source?: string, variant?: string) => ChartSource;
  };
  /**
   * meta information about the data dictionary
   */
  meta: {
    /**
     * all the enums
     */
    enums: EnumList;
    /**
     * whether the data dictionary is the root one (not nested in each other)
     */
    isDefault: boolean;
    /**
     * whether the data dictionary is still loading
     */
    isLoading: boolean;
  };
}

/**
 * Make a default context for user entities
 * @type {React.Context<ContextType>}
 */
const DefaultContext = createContext<ContextType>({
  dataDescriptors: {},
  metrics: {},
  meta: { isLoading: true, isDefault: true, enums: {} },
  groups: { byCategory: {}, byFamilyCategory: {}, families: {}, projectionMetrics: [] },
  api: {
    extractSource: () => void 0,
    extractChartInfo: () => {
      return {
        familyName: void 0,
        metricName: "",
        variantType: "",
        sourceName: void 0,
        nameToDisplay: ""
      };
    },
    handleMetricChanges: () => {
      return {
        metric: "",
        source: void 0,
        variant: ""
      };
    }
  },
  subsets: {
    farm: [],
    eventScoped: [],
    chartScoped: [],
    projectionsEnabled: []
  }
});

/**
 * Hook to get the user info within the context
 */
export const useDataDictionary = (): ContextType => useContext(DefaultContext);

/**
 * Data dictionary provider
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _DataDictionaryProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { farm } = useFarm();
  const { farmList } = useFarmList();
  const { event } = useEvent();
  const eventScope = event?.scope.type;

  const {
    meta: { isDefault }
  } = useDataDictionary();

  if (!isDefault) {
    console.error("You should not nest DataDictionaryContext.");

    Sentry.captureException("Nested data dictionary contexts.", {
      tags: {
        diagnostics: "dataDictionaryError"
      }
    });
  }

  /**
   * Attempt to get metrics
   */
  const metrics = useSelector<EnterpriseAppState, MetricCollection>((state) =>
    Enterprise.entities.getMetricList(state, { farmID: farm?.id })
  );

  /**
   * Get the chart list from the store
   * @type {ChartList}
   */
  const charts = useSelector<EnterpriseAppState, ChartList>((state) =>
    Enterprise.entities.getChartList(state)
  );

  /**
   * Attempt to get data descriptors
   * @type {DataDescriptorList}
   */
  const dataDictionary = useSelector<EnterpriseAppState, DataDescriptorList>((state) =>
    isDefault ? Enterprise.entities.getDataDictionary(state) : {}
  );

  /**
   * Attempt to get all enums
   * @type {DataDescriptorList}
   */
  const enums = useSelector<EnterpriseAppState, EnumList>((state) => Enterprise.entities.getEnums(state));

  /**
   * Pick only the metrics that do have "farm" variants if no farm is selected. Omit the rest
   */
  const allFarmMetrics = _.filter(metrics, ({ variants }) =>
    _.some(variants, (variant) =>
      _.isEqual(_.get(dataDictionary, [variant, "dataProperties", "type"]), "farm")
    )
  );

  /**
   * Group metrics by category
   */
  const categoryGroup = _.groupBy((farm ? metrics : allFarmMetrics) as MetricCollection, "category");
  /**
   * Add a layer of family group
   * if the group does not exist, group it by name for the sake of level consistency
   */
  const categoryFamilyGroup = _.mapValues(categoryGroup, (metric) =>
    _.groupBy(metric, ({ family, id }) => family || id)
  );

  /**
   * Get only a list of families
   */
  const metricFamilies = _.omit(
    _.groupBy(metrics, ({ family }) => family),
    "undefined"
  );
  /**
   * Get the farm subset (descriptors supported by the current farm)
   * @type {Array<DataDescriptorEntity["id"]>}
   */
  const farmSubset = useSelector<EnterpriseAppState, Array<DataDescriptorEntity["id"]>>((state) =>
    isDefault
      ? farm?.id
        ? _.map(
            Enterprise.entities.getDataDictionary(state, {
              filter: { supportedFarms: [farm.id], dataProperties: { type: "farm" } }
            }),
            "id"
          )
        : []
      : []
  );

  /**
   * Get the event scoped subset
   * @type {Array<DataDescriptorEntity["id"]>}
   */
  const eventScopedSubset = useSelector<EnterpriseAppState, Array<DataDescriptorEntity["id"]>>((state) =>
    isDefault
      ? farm?.id
        ? _.map(
            Enterprise.entities.getDataDictionary(state, {
              filter: { supportedFarms: [farm.id], dataProperties: { type: eventScope } }
            }),
            "id"
          )
        : []
      : []
  );

  /**
   * Get the data descriptors which support projections
   * @type {Array<DataDescriptorList[keyof DataDescriptorList]>}
   */
  const projectionEnabledSubset = useDeepCompareMemo(() => {
    return _.map(
      _.filter(
        dataDictionary,
        ({ projectionEnabledFarms, valueProperties: { maxProjectionInterval } }) =>
          !!maxProjectionInterval && farm?.id
            ? _.includes(projectionEnabledFarms, farm.id)
            : _.size(projectionEnabledFarms) > 0 // _.isEqual(_.sortBy(projectionEnabledFarms), _.sortBy(_.map(farmList, "id")))
      ),
      "id"
    );
  }, [farm, farmList]);

  /**
   * Get the metrics which support projections
   * @returns metric id or family name
   */
  const projectionMetrics = _.chain(metrics)
    .values()
    .filter((metric) => _.some(metric.variants, (variant) => _.includes(projectionEnabledSubset, variant)))
    .map(({ family, id }) => _.defaultTo(family, id))
    .value();

  /**
   * Count the number of metrics (which can indicate an update)
   * @type {number}
   */
  const metricCount = _.size(dataDictionary);

  /**
   * Get the data descriptors which may render a chart for the current scope.
   * Eg. filter out when a farm is picked but not supported or all farms are picked but the chart supports a single farm
   * @type {Array<DataDescriptorEntity["id"]>}
   */
  const chartSubset = useMemo(
    () =>
      isDefault
        ? _.map(
            _.pickBy(dataDictionary, (dataDescriptor) => {
              const { supportedFarms, supportedCharts } = dataDescriptor;
              const [mainChartID] = supportedCharts;

              const {
                flags: { isMultiFarmFilterSupported }
              } = charts[mainChartID];

              /**
               * Tell if the selected farm is supported by the data descriptor,
               * or whether the chart supports multiple farms
               */
              const supportsCurrentFarmView = farm?.id
                ? supportedFarms.includes(farm.id)
                : isMultiFarmFilterSupported;

              return supportsCurrentFarmView;
            }),
            "id"
          )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metricCount, farm, isDefault]
  );

  /**
   * Tell whether the fetching action has been dispatched
   * @type {boolean}
   */
  const dispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, DataDictionary.types.FETCH_METRICS)
  );

  /**
   * Tell whether the fetching action has been dispatched for enums
   * @type {boolean}
   */
  const enumsActionDispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, DataDictionary.types.FETCH_ENUMS)
  );

  /**
   * Fetch the data dictionary if it is not yet present in the store
   */
  useEffect(() => {
    if (isDefault && _.isEmpty(dataDictionary) && !dispatched) {
      dispatch(DataDictionary.actions.fetchMetrics.start());
    }
  }, [dispatch, isDefault, dispatched, dataDictionary]);

  /**
   * Fetch the enums if not yet present in the store
   */
  useEffect(() => {
    if (_.isEmpty(enums) && !enumsActionDispatched) {
      dispatch(DataDictionary.actions.fetchEnums.start());
    }
  }, [dispatch, enums, enumsActionDispatched]);

  /********** API section *******/

  /**
   * Util to extract the combination of metric/source/variant from a metric ID
   * @param variantID
   * @param metrics
   * @returns
   */
  const extractSource = (variantID: MetricEntity["id"]) => {
    const metric = _.find(metrics, ({ variants }) => _.includes(variants, variantID));

    if (metric?.family) {
      return { metric: metric.family, source: metric.id, variant: variantID };
    }

    return metric && { metric: metric.id, source: void 0, variant: variantID };
  };

  /**
   * Extracts all the information needed to display a chart
   * @param chart
   * @returns
   */
  const extractChartInfo = (chart: ChartSource) => {
    const familyName =
      chart &&
      _.get(
        _.find(metrics, ({ family }) => _.isEqual(family, chart.metric)),
        "family"
      );

    const metricName = (chart && _.get(metrics, [chart.metric, "name"])) as string;
    const variantType = chart && _.get(dataDictionary, [chart.variant, "dataProperties", "type"]);
    const sourceName = chart?.source && _.get(metrics, [chart.source, "sourceTypes", 0]);

    const nameToDisplay = familyName || metricName;

    return { familyName, metricName, variantType, sourceName, nameToDisplay };
  };

  /**
   * Handle a metric value change
   * @param {MetricEntity["id"]} metric
   * @param {string} source
   * @param {string} variant
   */
  const handleMetricChanges = (metric: MetricEntity["id"], source?: string, variant?: string) => {
    const initialSources = _.get(metricFamilies, metric);
    const initialSourceID = source || _.first(initialSources)?.id;

    const initialVariants = _.get(metrics, [initialSourceID || metric, "variants"]);
    /**
     * Get the farm type variant
     */
    const farmVariant = _.find(initialVariants, (variant) =>
      _.isEqual(_.get(dataDictionary, [variant, "dataProperties", "type"]), "farm")
    );
    /**
     * If no farm selected, then use the per farm variant, otherwise use the variant passed. Default to the first one
     */
    const initialVariantID = (
      !farm ? farmVariant : _.defaultTo(variant, farmVariant || _.first(initialVariants))
    ) as string;

    return { metric, source: initialSourceID, variant: initialVariantID };
  };

  /********** API section end *******/

  return isDefault ? (
    <DefaultContext.Provider
      value={{
        dataDescriptors: dataDictionary,
        metrics,
        meta: {
          isDefault: false,
          isLoading: _.isEmpty(dataDictionary),
          enums
        },
        subsets: {
          projectionsEnabled: projectionEnabledSubset,
          chartScoped: chartSubset,
          farm: farmSubset,
          eventScoped: eventScopedSubset
        },
        groups: {
          byCategory: categoryGroup,
          byFamilyCategory: categoryFamilyGroup,
          families: metricFamilies,
          projectionMetrics
        },
        api: {
          extractSource,
          extractChartInfo,
          handleMetricChanges
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  ) : (
    <>{children}</>
  );
};

/**
 * Context for the data dictionary
 * @type {{Consumer: React.ExoticComponent<React.ConsumerProps<ContextType>>, Provider: React.FunctionComponent<{}>}}
 */
export const DataDictionaryContext = {
  Consumer: DefaultContext.Consumer,
  Provider: _DataDictionaryProvider
};
