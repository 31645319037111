import { FC, ReactElement } from "react";
import { Spin, Skeleton } from "antd";
import * as React from "react";
import _ from "lodash";

import { classname } from "@ctra/utils";

import styles from "./ContentWrapper.module.less";

export interface ContentWrapperProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  /**
   * base color
   */
  accent?: "white" | "teal";
  /**
   * make it interactive by responding on hover
   */
  interactive?: boolean;
  /**
   * adds a padding to the component (optional as some of the content may don't need it)
   */
  padded?: boolean;
  /**
   * loading indicator
   */
  isLoading?: boolean;
  /**
   * loading tip
   */
  tip?: string;
  /**
   * title
   */
  title?: string | ReactElement;
  /**
   * footer
   */
  footer?: string | ReactElement;
}

/**
 * Content wrapper component with rounded edges and drop shadow
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | (React.ReactElement<any, string | React.JSXElementConstructor<any>> & undefined) | (Iterable<React.ReactNode> & undefined) | (React.ReactPortal & undefined) | (Iterable<ReactI18NextChild> & React.ReactElement<any, string | React.JSXElementConstructor<any>>) | (Iterable<ReactI18NextChild> & string) | (Iterable<ReactI18NextChild> & number) | (Iterable<ReactI18NextChild> & {}) | (Iterable<ReactI18NextChild> & Iterable<React.ReactNode>) | (Iterable<ReactI18NextChild> & React.ReactPortal) | (Iterable<ReactI18NextChild> & boolean) | (Iterable<ReactI18NextChild> & null) | (Iterable<ReactI18NextChild> & undefined)} children
 * @param {string | undefined} className
 * @param {string} accent
 * @param {boolean | undefined} interactive
 * @param {boolean} padded
 * @param {boolean} isLoading
 * @param {string | undefined} tip
 * @param {string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined} title
 * @param {string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined} footer
 * @param {Omit<React.PropsWithChildren<ContentWrapperProps>, "isLoading" | "padded" | "children" | "footer" | "interactive" | "className" | "tip" | "title" | "accent">} rest
 * @returns {JSX.Element}
 * @constructor
 */
const ContentWrapper: FC<ContentWrapperProps> = ({
  children,
  className,
  accent = "white",
  interactive,
  padded = true,
  isLoading = false,
  tip,
  title,
  footer,
  ...rest
}) => {
  return (
    <div
      className={classname(
        "ctra-contentWrapper",
        styles.ContentWrapper,
        styles.Border,
        interactive ? styles.Interactive : null,
        _.get(styles, [`Accent${_.upperFirst(accent)}`]) as string,
        className
      )}
      {...rest}
    >
      <Spin spinning={isLoading} tip={tip}>
        <Skeleton loading={isLoading}>
          {title && (
            <section className={classname(padded ? styles.Padded : null, styles.Title)}>{title}</section>
          )}
          <section className={padded ? styles.Padded : void 0}>{children}</section>
          {footer && (
            <section className={classname(padded ? styles.Padded : null, styles.Footer)}>{footer}</section>
          )}
        </Skeleton>
      </Spin>
    </div>
  );
};

export default ContentWrapper;
