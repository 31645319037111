import * as _ from "lodash";

import { memoize } from "@ctra/utils";

import { DataPointsBase } from "./DataPointsBase";

import { LineSource, LineData } from "../typings";
import { XAxisType } from "../xAxis";
import { Series } from "../";

/**
 * Normalizer class for line chart data
 */
class LineNormalizer extends DataPointsBase {
  protected entity: LineSource;

  constructor(source: LineSource) {
    super(source);
    this.entity = source;
  }

  /**
   * Process the incoming line chart data
   */
  @memoize
  formatData(): LineData["data"] {
    const data = _.reduce<NonNullable<LineSource["data"]>, NonNullable<LineData["data"]>>(
      this.entity.data,
      (acc, item, key) => {
        const series = Series.create(key, this.entity.meta.seriesType);
        const seriesField = series.getField();

        const axisType = XAxisType.create(this.entity.meta.xType);

        if (seriesField) {
          const list = _.map(item.points, ({ x, y, missing, context, anomaly }) => {
            const computedY = _.defaultTo(y, _.defaultTo(context?.projection, null));

            return {
              x: axisType.formatXAxis(x),
              y: missing ? null : computedY,
              anomaly,
              seriesField: key
            };
          });

          return [...acc, ...list];
        }

        return acc;
      },
      []
    );

    /**
     * Sort the line data by date
     */
    return _.sortBy(data, (point) => new Date(point.x).valueOf());
  }

  /**
   * Tell if the chart has an anomaly
   * @return {LineData["meta"]}
   */
  @memoize
  formatMetadata(): LineData["meta"] {
    const meta = DataPointsBase.prototype.formatMetadata.apply(this);
    const hasAnomaly = !!_.find(_.flatMap(this.entity.data, "points"), "anomaly");
    const hasMissingData = !!_.find(_.flatMap(this.entity.data, "points"), (point) => !_.isNumber(point.y));

    return { ...meta, hasAnomaly, hasMissingData };
  }
}

export { LineNormalizer };
