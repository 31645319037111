import * as _ from "lodash";

import { AsyncActionCreator, createAsyncActions } from "@ctra/utils";

import { FarmEntity } from "../farms";
import {
  FarmSummarySubscription,
  FarmSummarySubscriptionList,
  FarmSummarySubscriptionsResponse,
  FarmSummarySubscriptionsResponseItem
} from "./typings";
import types from "./types";

/**
 * Create a game plan
 * @category Action
 * @type {AsyncActionCreator<(farmID: FarmEntity["id"], start, end) => {start: any, end: any, farmID: number}, (response: Record<string, unknown>) => Record<string, unknown>, ({error, statusCode}: {error: any, statusCode: any}) => {error: any, statusCode: any}>}
 */
const createFarmSummary: AsyncActionCreator<
  (
    farmID: FarmEntity["id"],
    start: string,
    end: string
  ) => { start: string; end: string; farmID: FarmEntity["id"] },
  (response: Record<string, unknown>) => Record<string, unknown>,
  ({ error, statusCode }: { error: string; statusCode: number }) => { error: string; statusCode: number }
> = createAsyncActions(
  types.CREATE_FARM_SUMMARY,
  (farmID: FarmEntity["id"], start, end) => ({
    farmID,
    start,
    end
  }),
  (response: Record<string, unknown>) => response,
  ({ error, statusCode }) => ({ error, statusCode })
);

/**
 * Fetch the subscription list
 * @category Action
 * @type {AsyncActionCreator<() => {}, (response: FarmSummarySubscriptionsResponse) => {[p: number]: any, [p: symbol]: any, farmID: any}[], ({error, statusCode}: {error: any, statusCode: any}) => {error: any, statusCode: any}>}
 */
const fetchSubscriptionList: AsyncActionCreator<
  () => Record<string, unknown>,
  (response: FarmSummarySubscriptionsResponse) => FarmSummarySubscriptionList,
  ({ error, statusCode }: { error: string; statusCode: number }) => { error: string; statusCode: number }
> = createAsyncActions(
  types.FETCH_SUBSCRIPTION_LIST,
  () => ({}),
  (response): FarmSummarySubscriptionList =>
    _.map(_.get(response, "preferences", []), ({ farmId, ...rest }) => ({
      farmID: farmId,
      ...rest
    })),
  ({ error, statusCode }) => ({ error, statusCode })
);

/**
 * Update the subscription
 * @category Action
 * @type {AsyncActionCreator<(farmID: FarmEntity["id"], on: boolean) => {farmID: number, on: boolean}, ({farmId, ...rest}: FarmSummarySubscriptionsResponseItem) => {isEnabled: boolean, farmID: number, updatedTs: string}, ({error, statusCode}: {error: any, statusCode: any}) => {error: string, statusCode: number}>}
 */
const updateSubscription: AsyncActionCreator<
  (farmID: FarmEntity["id"], on: boolean) => { farmID: FarmEntity["id"]; isEnabled: boolean },
  (response: FarmSummarySubscriptionsResponseItem) => FarmSummarySubscription,
  ({ error, statusCode }: { error: string; statusCode: number }) => { error: string; statusCode: number }
> = createAsyncActions(
  types.UPDATE_SUBSCRIPTION,
  (farmID: FarmEntity["id"], on: boolean) => ({
    farmID,
    isEnabled: on
  }),
  ({ farmId, ...rest }: FarmSummarySubscriptionsResponseItem) => ({ farmID: farmId, ...rest }),
  ({ error, statusCode }) => ({ error, statusCode })
);

export default { createFarmSummary, fetchSubscriptionList, updateSubscription };
