import * as _ from "lodash";
import { NormalizedSchema, schema } from "normalizr";
import { CamelCase } from "type-fest";

import chartSchemas from "../chart/schemas";
import { ChartEntity, ChartList } from "../chart";

import {
  DataDescriptorEntity,
  DataDescriptorList,
  MetricCollection,
  MetricEntity,
  MetricSource,
  MetricVariantResponse
} from "./typings";

const metricVariant = new schema.Entity(
  "dataDescriptor",
  {
    supportedCharts: new schema.Array(chartSchemas.metricDefined)
  },
  {
    processStrategy: (
      value: MetricVariantResponse,
      { category, variants, __source }: MetricSource & { __source: Record<string, MetricVariantResponse> }
    ): Omit<DataDescriptorEntity, "supportedCharts"> & {
      supportedCharts: Array<any>;
      // remove this on the reducer as this is only needed to make the chart schema work
      __source: MetricVariantResponse;
    } => {
      const {
        id,
        name,
        type,
        dataSourceTypes,
        valueSettings: { unit, decimals, quantity, scoringMethod, min, max, conversions },
        features: {
          impactTracking,
          projection,
          analytics: { charts, displayLagDays, combinedDescriptors },
          gameplan,
          scorecard,
          kpiInsight
        },
        supportedFarms,
        flags
      } = value;

      const { isBeta } = _.defaultTo(flags, {} as MetricVariantResponse["flags"]);
      const isImperialSupported = _.some(conversions, (conversion) => conversion.name === "Imperial");

      const { maxProjectionInterval, supportedFarms: projectionSupport } = _.defaultTo(projection, {
        supportedFarms: []
      }) as NonNullable<MetricVariantResponse["features"]["projection"]>;

      // eslint-disable-next-line prefer-const
      let newProjectionInterval;

      // TODO enable this when the composed metrics start supporting projections
      // /**
      //  * Make sure we get the maxProjectionInterval from the combinedDescriptors if there are any,
      //  * this will allow the projections for charts where the parent descriptor does not have a maxProjectionInterval.
      //  */
      // if (!maxProjectionInterval && _.size(combinedDescriptors)) {
      //   const combinedIdList = _.values(combinedDescriptors);
      //   const combinedVariants = _.pick(__source, combinedIdList);
      //
      //   const projectionIntervals = _.compact(
      //     _.map(combinedVariants, "features.projection.maxProjectionInterval")
      //   );
      //
      //   if (!_.isEmpty(projectionIntervals)) {
      //     newProjectionInterval = _.first(projectionIntervals);
      //   }
      // }

      return {
        __source: newProjectionInterval
          ? {
              ...value,
              features: {
                ...value.features,
                projection: {
                  ...value.features.projection,
                  maxProjectionInterval: newProjectionInterval
                }
              }
            }
          : value,
        id,
        description: "",
        supportedCharts: _.map(charts, (chartName: string) => ({
          id: `${id}-${chartName}`,
          view: chartName
        })),
        supportedFarms,
        projectionEnabledFarms: _.defaultTo(projectionSupport, []),
        dataProperties: {
          ...(combinedDescriptors
            ? {
                combinedDescriptors: _.values(combinedDescriptors)
              }
            : {}),
          category, // legacy TODO remove (because we will use the category from the metric)
          type,
          sourceTypes: dataSourceTypes,
          typeName: name
        },
        valueProperties: {
          // TODO remove this when the composed metrics start supporting projections
          maxProjectionInterval: _.size(combinedDescriptors)
            ? void 0
            : _.defaultTo(maxProjectionInterval, newProjectionInterval),
          acquisitionMethod: scoringMethod,
          decimals,
          max,
          min,
          offset: displayLagDays,
          type: _.defaultTo(quantity, null),
          units: _.reduce(
            _.defaultTo(conversions, []),
            (acc, { name, unit }) => {
              acc[_.toLower(name) as CamelCase<typeof name>] = unit;

              return acc;
            },
            { si: unit } as DataDescriptorEntity["valueProperties"]["units"]
          )
        },
        flags: {
          isBeta,
          isImperialSupported,
          isProjectionSupported: !_.isEmpty(projection),
          isKPIInsight: kpiInsight,
          isGamePlanKPI: gameplan,
          isScorecardSupported: scorecard,
          isImpactTrackingSupported: impactTracking
        }
      };
    }
  }
);

const metric = new schema.Entity(
  "metric",
  {
    variants: new schema.Array(metricVariant)
  },
  {
    processStrategy: (value, parent) => ({
      ...value,
      __source: _.keyBy(_.flatMap(parent, "variants"), "id")
    })
  }
);

const metricList = new schema.Array(metric);

export type NormalizedDataDictionary = NormalizedSchema<
  {
    chart: Record<ChartEntity["id"], ChartEntity>;
    dataDescriptor: DataDescriptorList;
  },
  Array<DataDescriptorEntity["id"]>
>;

export type NormalizedDataDescriptor = NormalizedSchema<
  {
    chart: Record<ChartEntity["id"], ChartEntity>;
    dataDescriptor: DataDescriptorList;
  },
  DataDescriptorEntity["id"]
>;

export type NormalizedMetricCollection = NormalizedSchema<
  {
    metric: MetricCollection;
    chart: ChartList;
    dataDescriptor: DataDescriptorList;
  },
  Array<MetricEntity["id"]>
>;

export type NormalizedMetric = NormalizedSchema<
  {
    metric: MetricCollection;
    chart: ChartList;
    dataDescriptor: DataDescriptorList;
  },
  MetricEntity["id"]
>;

export default {
  metric,
  metricList,
  metricVariant
};
