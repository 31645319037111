/* eslint @typescript-eslint/no-explicit-any: 0 */

import { ComponentType } from "react";
import * as _ from "lodash";

import { i18nTranslate, Enterprise } from "@ctra/i18n";
import { GenericInsightEntity, InsightContext, UserEntity } from "@ctra/api";
import { Epoch, memoize } from "@ctra/utils";

/**
 * Generic insight base class to give some sort of structured API on the insight entities
 * @todo extend this in subclasses
 * @todo add base functionality for resolutions/validations/recommendations, etc.
 */
class GenericInsightBase {
  /**
   * The underlying insight entity
   */
  protected entity: GenericInsightEntity;

  constructor(insight: GenericInsightEntity) {
    this.entity = insight;
  }

  /**
   * Get the metadata for the insight
   * @todo implement in subclass to organise anything messy
   */
  @memoize
  getMetadata(): Record<string, any> {
    return this.entity.metadata;
  }

  /**
   * Get the context for the insight
   * @todo implement in subclass if needed
   */
  @memoize
  getContext(): InsightContext {
    return this.entity.context;
  }

  /**
   * Insight may have different components based on the type name
   * or other data. This should give you a rundown of all the
   * child components an insghts may use.
   * @todo extend in subclass
   */
  @memoize
  getComponents(): Record<"ListIcon" | "Icon" | "Validation" | "Body", ComponentType<any>> {
    return {
      ListIcon: () => null,
      Icon: () => null,
      Validation: () => null,
      Body: () => null
    };
  }

  @memoize
  getTranslationParams(): Record<string, any> {
    return {};
  }

  /**
   * Get insight title
   * @param params {{}}
   */
  @memoize
  getTitle(params?: Record<string, unknown>): string {
    const {
      entity: { title }
    } = Enterprise.insights;

    return i18nTranslate(title(this.entity.insightType), {
      ...this.getMetadata(),
      ...this.getTranslationParams(),
      ...params
    });
  }

  /**
   * Get insight description
   * @param params {{}}
   */
  @memoize
  getDescription(params?: Record<string, unknown>): string {
    const {
      entity: { description }
    } = Enterprise.insights;

    return i18nTranslate(description(this.entity.insightType), {
      ...this.getMetadata(),
      ...this.getTranslationParams(),
      ...params
    });
  }

  /**
   * Get insight workflow state description
   * @param params {{}}
   */
  @memoize
  getStateDescription(params?: Record<string, unknown>): string {
    const { stateSince } = Enterprise.insights;

    return i18nTranslate(stateSince(this.entity.workflowState), {
      ...this.getMetadata(),
      ...params
    });
  }

  /**
   * Start moment
   * @note as we usually use this for manipulation, better to use than startEpoch
   */
  @memoize
  getStartMoment(): ReturnType<typeof Epoch.asMoment> {
    return Epoch.asMoment(this.entity.startEpoch);
  }

  /**
   * End moment
   * @note as we usually use this for manipulation, better to use than startEpoch
   */
  @memoize
  getEndMoment(): ReturnType<typeof Epoch.asMoment> {
    return Epoch.asMoment(this.entity.endEpoch);
  }

  /**
   * Tell whether the user has seeeen this insight
   * @param userID
   */
  isSeen(userID?: UserEntity["id"]): boolean {
    return !!userID && this.entity.seenBy.includes(userID);
  }

  /**
   * Tell if the insight given is equal to the entity stored
   * @param insight
   */
  isEqual(insight: GenericInsightEntity): boolean {
    return _.isEqual(this.entity, insight);
  }
}

export { GenericInsightBase };
