import * as _ from "lodash";

import { GenericInsightEntity, InsightType, InsightTypeEntity } from "@ctra/api";
import { getInheritedPropertyNames } from "@ctra/utils";

import { KPIInsight } from "./KPIInsight";
import { GenericInsightBase } from "./Base";
import { OnboardingInsight } from "./OnboardingInsight";
import { VulcanV2Insight } from "./VulcanV2Insight";

/**
 * @todo figure out a way to handle unions so each insight will expose the proper API
 */
type RegisteredInsights = typeof KPIInsight | typeof OnboardingInsight | typeof VulcanV2Insight;

/**
 * A factory to create a nice API around the insights
 * @example
 * ```ts
 * GenericInsight.create(insightObject, extra, info, to, pass);
 * // instanceof KPIInsight extends GenericInsightBase
 * ```
 */
class Factory {
  /**
   * Keep all the registered insights here
   */
  private static _registry = new Map();

  /**
   * Register a class of insight
   * @param typeName
   * @param cls
   */
  static register<I extends RegisteredInsights>(typeName: InsightTypeEntity["typeName"], cls: I): void {
    if (!Factory._registry.has(typeName)) {
      Factory._registry.set(typeName, cls);
    } else {
      throw new Error(`${typeName} is already set in the registry.`);
    }
  }

  /**
   * Create an insight object
   * @param insight
   * @param rest
   */
  static create<I extends RegisteredInsights>(
    insight: GenericInsightEntity,
    ...rest: unknown[]
  ): InstanceType<I> {
    const { insightType } = insight;
    let cls = Factory._registry.get(insightType);

    if (!cls) {
      console.warn(`${insightType} is not yet in the registry. Using GenericInsightBase as constructor.`);
      cls = GenericInsightBase;
    }

    return new cls(insight, ...rest);
  }

  /**
   * Serialize any insight object to {} for allowing spread operations
   * @param instance
   */
  static serialize = <T extends InstanceType<RegisteredInsights>>(instance: T): Record<keyof T, T[keyof T]> =>
    _.reduce<keyof T, Record<keyof T, T[keyof T]>>(
      getInheritedPropertyNames(instance, GenericInsightBase) as Array<keyof T>,
      (result, key) => {
        // @ts-ignore - binding is necessary
        result[key] = _.isFunction(instance[key]) ? instance[key].bind(instance) : instance[key];

        return result;
      },
      {} as Record<keyof T, T[keyof T]>
    );

  /**
   * Get the registered insight type names
   */
  static getRegisteredInsights = (): Array<string> => {
    return Array.from(Factory._registry.keys());
  };
}

Factory.register(InsightType.farmKPI, KPIInsight);
Factory.register(InsightType.vulcanV2, VulcanV2Insight);
Factory.register(InsightType.onboarding, OnboardingInsight);

export { Factory };
