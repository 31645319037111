import * as _ from "lodash";

import { memoize } from "@ctra/utils";
import { DataPointsMeta, ChartSeriesType } from "@ctra/api";
import { Enterprise as Content, i18nTranslate } from "@ctra/i18n";
import { strictParseInt } from "@ctra/utils";

import { ChartViewBase } from "./Base";
import { XAxisType } from "./xAxis";

const {
  chart: {
    series: seriesItem,
    unit,
    seriesTitle,
    line: { axisTitle }
  }
} = Content;

/**
 * DataPoints View Base class
 */
class DataPointsBase<M extends DataPointsMeta = DataPointsMeta> extends ChartViewBase<M> {
  /**
   * Get the metadata
   * @return {M}
   */
  @memoize
  getMetadata(): M {
    const { series: { keys } = {}, yAxis, seriesType, axis } = this.metadata;
    const labelTranslationVariant = this.getTranslatedXAxisLabels(axis?.x.labels?.short);

    return {
      ...this.metadata,
      series: {
        title: i18nTranslate(seriesTitle(seriesType as string)),
        keys: this.getTranslatedSeries(keys)
      },
      axis: {
        x: {
          title: i18nTranslate(axisTitle.x),
          labels: {
            short: labelTranslationVariant({ long: false }),
            long: labelTranslationVariant({ long: true })
          }
        },
        y: {
          title: yAxis?.unit ? i18nTranslate(unit.custom(yAxis.unit)) : i18nTranslate(unit.default)
        }
      }
    };
  }

  /**
   * Translate series only if they are translatable
   * Basically, we do not want to translate farm names, numbers etc
   * @param {Record<string, string>} series
   * @return {Record<string, string>}
   * @private
   */
  private getTranslatedSeries(series?: Record<string, string>): Record<string, string> {
    return _.reduce<Record<string, string>, Record<string, string>>(
      series,
      (acc, item, key) => {
        acc[key] =
          this.entity.flags.isSeriesTranslatable && !_.isInteger(strictParseInt(item))
            ? i18nTranslate(seriesItem(item))
            : item;

        return acc;
      },
      {}
    );
  }

  /**
   * Translate xAxis labels based on the type
   * @param {Record<string, string>} labels
   * @return {(options: {long: boolean}) => Record<string, string>}
   * @private
   */
  private getTranslatedXAxisLabels(
    labels?: Record<string, string>
  ): (options: { long: boolean }) => Record<string, string> {
    const xAxisType = XAxisType.create(this.metadata.xType);

    return function (options): Record<string, string> {
      return _.reduce<Record<string, string>, Record<string, string>>(
        labels,
        (acc, label) => {
          /* istanbul ignore next */
          acc[label] = xAxisType.getTranslatedLabel(label, { long: options.long });

          return acc;
        },
        {}
      );
    };
  }
}

export { DataPointsBase };
