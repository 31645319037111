import { FC, createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import { Enterprise as App, EnterpriseAppState, FarmSummaries, FarmSummarySubscriptionList } from "@ctra/api";
import { isDispatched, isPending } from "@ctra/utils";

type ContextType = {
  subscriptions: FarmSummarySubscriptionList;
  api: {
    update: (...args: Parameters<typeof FarmSummaries.actions.updateSubscription.start>) => void;
  };
  meta: {
    isLoading: boolean;
    isUpdating: boolean;
  };
};

const DefaultContext = createContext<ContextType>({
  meta: {
    isLoading: true,
    isUpdating: false
  },
  api: {
    update: _.noop
  },
  subscriptions: []
});

/**
 * Farm summary subscriptions provider
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @returns {JSX.Element}
 */
const _FarmSummarySubscriptionsProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  /**
   * Read the subscriptions from the store
   * @type {FarmSummarySubscriptionList}
   */
  const subscriptions: FarmSummarySubscriptionList = useSelector<
    EnterpriseAppState,
    FarmSummarySubscriptionList
  >((state) => App.entities.getFarmSummarySubscriptions(state));

  /**
   * Tell whether the fetching has started
   * @type {boolean}
   */
  const isFetchDispatched: boolean = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, FarmSummaries.types.FETCH_SUBSCRIPTION_LIST)
  );

  /**
   * Tell whether the fetching is in progress
   * @type {boolean}
   */
  const isLoading: boolean = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, FarmSummaries.types.FETCH_SUBSCRIPTION_LIST)
  );

  /**
   * Tell whether the update is in progress
   * @type {boolean}
   */
  const isUpdating: boolean = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, FarmSummaries.types.UPDATE_SUBSCRIPTION)
  );

  useEffect(() => {
    if (!isFetchDispatched) {
      dispatch(FarmSummaries.actions.fetchSubscriptionList.start());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchDispatched]);

  return (
    <DefaultContext.Provider
      value={{
        meta: {
          isLoading,
          isUpdating
        },
        api: {
          update: (...args) => dispatch(FarmSummaries.actions.updateSubscription.start(...args))
        },
        subscriptions
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const FarmSummarySubscriptionsContext = {
  Provider: _FarmSummarySubscriptionsProvider,
  Consumer: DefaultContext.Consumer
};

export const useFarmSummarySubscriptions = () => useContext<ContextType>(DefaultContext);
