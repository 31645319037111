import { FC } from "react";

import { GAContext } from "@ctra/analytics";
import { useTranslation, Enterprise } from "@ctra/i18n";
import { Breadcrumbs } from "@ctra/api";

import { InsightList, GACategories as InsightsGACategories, useLatestInsights } from "@insights";
import { Routes } from "@routes";

import styles from "./InsightListPage.module.less";

/**
 * Insight list page
 * @constructor
 */
const InsightListPage: FC = () => {
  const { t } = useTranslation();
  const { insightListQuery, insightListName } = useLatestInsights();

  return (
    <>
      <Breadcrumbs.components.Breadcrumb
        path={Routes.app.insights.index}
        title={t<string>(Enterprise.navigation.main.insights)}
      />
      <section className={styles.PageContent}>
        <InsightList query={insightListQuery} list={`${insightListName}-insightListPage`} wide />
      </section>
    </>
  );
};

export default InsightListPage;
