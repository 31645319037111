import { createAsyncActions, UnitSystem } from "@ctra/utils";
import { PartialDeep } from "type-fest";

import types from "./types";
import { SandboxInfo, UserPreferencesAPI, UserPreferencesEntity, UserPreferencesSource } from "./typings";

/**
 * Combine both responses into one object
 * @param {string} timezone
 * @param {UnitSystemParam} unitSystem
 * @param {"nl-NL" | "en-US" | "es-MX"} locale
 * @param {boolean} isEnabled
 * @param {number} farmId
 * @return {UserPreferencesEntity}
 */
const handleSuccess = (
  { timezone, unitSystem, locale }: Partial<UserPreferencesSource> = {},
  { isEnabled, farmId }: Partial<SandboxInfo> = {}
): PartialDeep<UserPreferencesEntity> => {
  return {
    timezone,
    locale,
    sandbox: {
      isEnabled,
      farmId
    },
    /**
     * Make the unit system lowercase
     */
    unitSystem: unitSystem ? (unitSystem.toLowerCase() as UnitSystem) : void 0
  };
};

/**
 * Fetch the user preferences
 * @category Action
 */
const fetchUserPreferences = createAsyncActions(
  types.FETCH_USER_PREFERENCES,
  /**
   * Fetch the localisation preferences and the sandbox preferences together
   * @return {{}}
   */
  (): Record<string, unknown> => ({}),
  handleSuccess,
  /**
   * @param {string} error
   * @param {number} statusCode
   * @return {{error: string, statusCode: number}}
   */
  (error: string, statusCode: number): { error: string; statusCode: number } => ({ error, statusCode })
);

/**
 * Set the user preferences
 * @category Action
 */
const setUserPreferences = createAsyncActions(
  types.SET_USER_PREFERENCES,
  (userPreferences: UserPreferencesAPI): UserPreferencesAPI => userPreferences,
  handleSuccess,
  (error: string, statusCode: number): { error: string; statusCode: number } => ({ error, statusCode })
);

export default {
  fetchUserPreferences,
  setUserPreferences
};
