import enUS from "antd/es/locale/en_US";
import esES from "antd/es/locale/es_ES";
import nlNL from "antd/es/locale/nl_NL";

// @todo find the proper way to export Input.Group and other ant design defaults as they may not be covered in "formik-antd"
export {
  Input as AntDesignInput,
  Select as AntDesignSelect,
  DatePicker as AntDesignDatePicker,
  Checkbox as AntDesignCheckbox,
  Radio as AntDesignRadio,
  Slider as AntDesignSlider,
  Typography as AntDesignTypography,
  Button as AntDesignButton,
  Form as AntDesignForm
} from "antd";

export * from "antd";

export {
  Form,
  Input,
  DatePicker,
  AutoComplete,
  Select,
  InputNumber,
  Slider,
  Checkbox,
  Radio
} from "formik-antd";

export { Fab as TinyFab, Action as TinyFabAction } from "react-tiny-fab-ak";
export * from "@ant-design/icons";
export * from "antd/lib/table";
export * from "react-dnd";
export * from "react-dnd-html5-backend";

export * from "./elements";
export { Typography, Button, Digits } from "./elements";
export * from "./modules";
export * from "./context";

/**
 * Re-export custom icons with the same name as ant icons
 */
export {
  UserOutlined,
  ExportOutlined,
  LineChartOutlined,
  SettingOutlined,
  CompassOutlined,
  NotificationOutlined,
  UnlockOutlined,
  AppstoreOutlined,
  ClockCircleOutlined,
  GiftOutlined,
  MailOutlined,
  PhoneOutlined,
  BarChartOutlined,
  CheckSquareOutlined,
  ProfileOutlined
} from "./elements";

export { enUS, esES, nlNL };
