import { ajax, AjaxResponse } from "rxjs/ajax";
import * as _ from "lodash";
import QueryString from "query-string";
import { Observable } from "rxjs/internal/Observable";

import { isProduction, Debug } from "@ctra/utils";

Debug.create("dataRequest", "Data Request Util", { prefixColor: "#316650" });

/**
 * Make a sandbox enabled service URI
 * @param {string} service
 * @param {Observable<unknown>} state$
 * @return {string}
 */
export const withSandboxPrefix = (service: string, state$: Observable<unknown>): string => {
  /**
   * Get the sandbox preference. This is really hacky as we should not access state ideally
   * because this module should not be aware of the structure of the state. Otherwise, we
   * cannot use it in other apps.
   * @type {any}
   */
  const sandboxEnabled = _.get(state$, ["value", "preferences", "sandbox", "isEnabled"], false);

  return sandboxEnabled ? (isProduction() ? service : `${service}.sandbox`) : service;
};

/**
 * Build an API URL based on the build env
 * @param {string} urlTemplate
 * @param {Record<string, unknown>} envOverrides - override resolving REACT_APP_BUILD_ENV
 * @return {(params?: Record<string, unknown>) => string}
 */
export const makeApiURL =
  (urlTemplate: string, envOverrides: Record<string, unknown> = {}, isBeta?: boolean) =>
  (
    /**
     * named URI params
     */
    params: Record<string, unknown> = {}
  ): string => {
    const processEnv = process.env.REACT_APP_BUILD_ENV;
    const env = processEnv ? envOverrides[processEnv] || processEnv : "staging";

    return _.template(urlTemplate)({
      ...params,
      env: isProduction() ? (isBeta ? ".beta" : "") : `.${env}`
    });
  };

/**
 * Make an "azurewebsites" API url based on the build env
 * @param {string} service
 * @param {string} uri
 * @param {Record<string, unknown>} envOverrides
 * @param {boolean} isBeta
 * @returns {(params?: Record<string, unknown>) => string}
 */
export const makeAzureApiURL =
  (service: string, uri: string, envOverrides: Record<string, unknown> = {}, isBeta?: boolean) =>
  (params: Record<string, unknown> = {}): string =>
    makeApiURL(`https://${service}<%= env %>.connecterra.ai${uri}`, envOverrides, isBeta)(params);

/**
 * Build a url with a query string
 * @param url
 * @param params
 */
export const buildURLWithQueryString = (url: string, params: Record<string, unknown>) => {
  const parsed = QueryString.parseUrl(url);
  const query = _.assign(parsed.query, params);

  return `${parsed.url}?${QueryString.stringify(query)}`;
};

/**
 * Generate authorization header from the given token
 * @param token
 */
const getAuthorizationHeader = (token: string) => ({
  headers: {
    Authorization: `Bearer ${token}`
  }
});

/**
 * Request defaults
 */
const requestDefaults = {
  crossDomain: true,
  headers: {
    "Content-Type": "application/json"
  }
};

interface RequestOptions {
  body?: Record<string, unknown>;
}

type ObservableRequest = <T>(
  method: string,
  url: string,
  options?: RequestOptions
) => Observable<AjaxResponse<T>>;

type ObservableWrappedRequest = <T = unknown>(
  url: string,
  options?: RequestOptions
) => Observable<AjaxResponse<T>>;

type API = {
  token: string;
  registerToken: (t: string) => void;
  makeRequest: ObservableRequest;
  GET: ObservableWrappedRequest;
  POST: ObservableWrappedRequest;
  PUT: ObservableWrappedRequest;
  PATCH: ObservableWrappedRequest;
  DELETE: ObservableWrappedRequest;
};

/**
 * Data request wrapper
 */
export const Request: API = {
  token: "",

  /**
   * Register the auth token
   * @param t
   */
  registerToken(t: string) {
    Debug.dataRequest.info("Registering new token", { _token: t });
    Request.token = t;
  },

  /**
   * @param method
   * @param url
   * @param options
   */
  makeRequest(method, url, options) {
    const headerWithToken = getAuthorizationHeader(Request.token);

    return ajax(
      _.merge(
        {},
        requestDefaults,
        {
          method,
          url
        },
        headerWithToken,
        options
      )
    );
  },

  /**
   * @param {string} url
   * @param {{}} options
   * @returns {Observable<AjaxResponse<unknown>>}
   * @constructor
   */
  GET: (url, options = {}) => {
    const { body, ...rest } = options;

    /**
     * Add GET queries to URL
     */
    const urlWithQuery = body ? buildURLWithQueryString(url, body) : url;

    return Request.makeRequest("GET", urlWithQuery, rest);
  },

  /**
   * @param {string} url
   * @param {RequestOptions | undefined} options
   * @returns {Observable<AjaxResponse<unknown>>}
   * @constructor
   */
  POST: (url, options) => Request.makeRequest("POST", url, options),

  /**
   * @param {string} url
   * @param {RequestOptions | undefined} options
   * @returns {Observable<AjaxResponse<unknown>>}
   * @constructor
   */
  PUT: (url, options) => Request.makeRequest("PUT", url, options),

  /**
   * @param {string} url
   * @param {RequestOptions | undefined} options
   * @returns {Observable<AjaxResponse<unknown>>}
   * @constructor
   */
  PATCH: (url, options) => Request.makeRequest("PATCH", url, options),

  /**
   * @param {string} url
   * @param {RequestOptions | undefined} options
   * @returns {Observable<AjaxResponse<unknown>>}
   * @constructor
   */
  DELETE: (url, options) => Request.makeRequest("DELETE", url, options)
};
